import React, { ReactNode } from "react";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import {
  withRouter,
  RouteComponentProps,
  useParams,
  useHistory,
  Link,
} from "react-router-dom";
import CrewAssignStatusModal from "../components/modals/CrewAssignStatusModal";
import CauseCodeModal from "../components/modals/CauseCodeModal";
import {
  JobDetail,
  getJobDetailById,
  updateJob,
  getLongLat,
  getCircuitMap,
  getJobHistoryByPremiseId,
  HistoricJob,
  AMIJobStatus,
  getAMIJobStatus,
  SingleComment,
} from "../lib/Api";
import ERTModal from "../components/modals/ERTModal";
import JobSubTypeModal from "../components/modals/JobSubTypeModal";
import TakePhotoModal from "../components/modals/TakePhotoModal";
import UploadPhotoModal from "../components/modals/UploadPhotoModal";
import CancelCloseModal from "../components/modals/CancelCloseModal";
import * as Util from "../lib/Utility";
import { useAppSelector, useAppDispatch } from "../lib/hooks";
import { setLoading } from "../features/jobSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import HistoricJobModal from "../components/modals/HistoricJobModal";
import DamageAssessmentFormModal from "../components/modals/DamageAssessmentForm";
import he from "he";
import TreeTrimActivityList from "../components/TreeTrim/TreeTrimActivityList";
import {
  DamageAssessmentComment,
  DamageAssessmentOnOnArrivalComment,
} from "../lib/api/damageAssessmentApi";
import {
  clearTreeTrimConfirmQuestions,
  getTreeTrimActivities,
  getTreeTrimOutageInfo,
} from "../features/treeTrimSlice";
import TreeTrimCancelCloseModal from "../components/modals/TreeTrimCancelCloseModal";
import TreeTrimOutageInfoList from "../components/TreeTrim/TreeTrimOutageInfoList";
import { trackLocationData } from "../lib/api/trackLocationApi";

const getMapLink = (lat: number | string, lng: number | string) => {
  if (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform)
  ) {
    return `https://maps.apple.com/?daddr=${lat},${lng}`;
  } else {
    return `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;
  }
};

const Row = (props: {
  label: string;
  value?: string | number | ReactNode;
  children?: any;
}) => {
  return (
    <div
      className="py-0.5 px-1 min-h-3.5 flex justify-between border-b border-gray-300 items-center"
      key={props.label}
    >
      <div className="mr-2">{props.label}:</div>
      <div style={{ wordBreak: "break-word" }} className="text-right">
        {props.children
          ? props.children
          : props.value && props.value !== ""
          ? props.value
          : "N/A"}
      </div>
    </div>
  );
};

type Tab = "Comments" | "Job History" | "Activity" | "Outage Info";

const DetailsPage: React.FC<RouteComponentProps> = (): React.ReactElement => {
  const urlParams = useParams<{ id: string }>();
  const [jobDetail, setJobDetail] = React.useState<JobDetail | null>(null);
  const [amiData, setAMIData] = React.useState<AMIInfo>(nothingYet());
  const [enteredComment, setEnteredComment] = React.useState("");
  const history = useHistory();
  const [latLong, setLatLong] = React.useState({ lat: "", long: "" });
  const dispatch = useAppDispatch();
  const role = useAppSelector((state) => state.role.value);
  const loading = useAppSelector((state) => state.jobs.loading);
  const [currentTab, setCurrentTab] = React.useState<Tab>("Comments");
  const [historicJobs, setHistoricJobs] = React.useState<HistoricJob[]>([]);
  const [jobHistoryErr, setJobHistoryErr] = React.useState(false);
  const [sortKey, setSortKey] = React.useState<SortKey | null>("outageDate");
  const [sortDir, setSortDir] = React.useState<SortDir>("asc");

  React.useEffect(() => {
    const getLatLong = async () => {
      const x = Number(jobDetail?.xCord) / 100;
      const y = Number(jobDetail?.yCord) / 100;
      if (!isNaN(x) && !isNaN(y)) {
        const res = await getLongLat(x, y);
        if (res.tag === "ok") {
          if (!("geometries" in res.data) || "code" in res.data.geometries[0]) {
            alert("Error getting job location");
          } else {
            setLatLong({
              lat: res.data.geometries[0].y.toString(),
              long: res.data.geometries[0].x.toString(),
            });
          }
        } else {
          alert("Error getting job location");
        }
      }
    };

    const getJobHistory = async () => {
      if (jobDetail && jobDetail.premise && jobDetail.premise !== "0") {
        const res = await getJobHistoryByPremiseId(jobDetail.premise);
        if (res.tag === "ok") {
          setHistoricJobs(res.data);
        } else {
          if (res.err.response.status !== 404) {
            setJobHistoryErr(true);
          }
        }
      }
    };

    getJobHistory();
    getLatLong();
  }, [jobDetail]);

  const getJobDetail = async () => {
    dispatch(setLoading(true));
    const res = await getJobDetailById(urlParams.id);
    if (res.tag === "ok") {
      setJobDetail(res.data);
      dispatch(getTreeTrimActivities(res.data.jobId));
      dispatch(getTreeTrimOutageInfo(res.data.jobId));
    } else {
      setJobDetail(null);
    }
    dispatch(setLoading(false));
  };

  const afterSubmit = () => setTimeout(() => getJobDetail(), 1500);

  React.useEffect(() => {
    getJobDetail();
    dispatch(clearTreeTrimConfirmQuestions());
  }, []);

  React.useEffect(() => {
    const getAMI = async () => {
      if (jobDetail) {
        const jobStatusAMI = await getAMIJobStatus(jobDetail.jobId);

        if (jobStatusAMI.tag === "ok") {
          setAMIData({ tag: "ok", res: jobStatusAMI.data });
        }
      }
    };

    getAMI();
  }, [jobDetail]);

  const handleViewCircuitMap: React.MouseEventHandler<
    HTMLButtonElement
  > = async () => {
    if (jobDetail?.circuit) {
      var translated = Util.oneLinesPDFUrl(jobDetail?.circuit ?? "");

      if (translated === "") {
        alert("Error translating Circuit to file name");
      }

      console.log(
        "translated Circuit name from: ",
        jobDetail?.circuit,
        " to: ",
        translated
      );

      // otherwise call the new APIM
      const res = await getCircuitMap(translated);
      if (res.tag === "ok") {
        const url = window.URL.createObjectURL(res.data);
        const a = document.createElement("a");
        a.href = url;
        a.target = "_blank";
        document.body.append(a);
        a.click();
        a.remove();
      } else {
        alert("Error getting Circuit Map");
      }
    }
  };

  const setSort = (key: SortKey) => {
    if (key === sortKey) {
      if (sortDir === "desc") {
        setSortDir("asc");
      } else if (sortDir === "asc") {
        setSortDir("desc");
        setSortKey(null);
      }
    } else {
      setSortKey(key);
    }
  };

  const renderCurrentTab = (tab: Tab) => {
    switch (tab) {
      case "Activity":
        return <TreeTrimActivityList job={jobDetail!} />;
      case "Comments":
        return (
          <JobCommentsList
            jobDetail={jobDetail}
            getJobDetail={getJobDetail}
            role={role}
          />
        );
      case "Job History":
        return (
          <JobHistoryList
            setSort={setSort}
            sortDir={sortDir}
            sortKey={sortKey}
            historicJobs={historicJobs}
            jobHistoryError={jobHistoryErr}
          />
        );
      case "Outage Info":
        return <TreeTrimOutageInfoList job={jobDetail!} />;
      default:
        break;
    }
  };

  const isTreeTrimJob = (): boolean => {
    return Util.TreeTrimJobs.includes(jobDetail?.jobType!);
  };

  // finished loading and the job or job status is not valid show the not available message for entire job
  if (
    !loading &&
    (jobDetail === null ||
      Util.jobStatusOptions.find((x) => x.value === jobDetail?.jobStatus) ===
        undefined)
  ) {
    return (
      <>
        <div className="text-black p-1 pt-0.25 text-base md:w-3/4 mx-auto">
          <div className="inline-flex flex-wrap mb-0.5 mt-2.5 justify-center">
            Job is in a closed status or not available.
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="text-black p-1 pt-0.25 text-base md:w-3/4 mx-auto">
      <div className="inline-flex flex-wrap mb-0.5 mt-0.5">
        {role?.tag === "employee" && (
          <Link
            className="mr-0.5 mb-0.25 bg-dte-500 py-0.25 px-0.5 text-white"
            to={`/map?x=${latLong.long}&y=${latLong.lat}`}
          >
            View on Map
          </Link>
        )}
        <a
          className="mr-0.5 mb-0.25 bg-dte-500 py-0.25 px-0.5 text-white"
          href={getMapLink(latLong.lat, latLong.long)}
          target="_blank"
          rel="noopener noreferrer"
        >
          Driving Directions
          <FontAwesomeIcon className="ml-1" icon={faExternalLinkAlt} />
        </a>
        <button
          className="mr-0.5 mb-0.25 bg-dte-500 py-0.25 px-0.5 text-white"
          onClick={handleViewCircuitMap}
          disabled={!jobDetail?.circuit}
        >
          View Circuit Map
          <FontAwesomeIcon className="ml-1" icon={faExternalLinkAlt} />
        </button>
        <TakePhotoModal
          jobId={jobDetail?.jobId ?? ""}
          afterSubmit={afterSubmit}
        />
        <UploadPhotoModal
          jobId={jobDetail?.jobId ?? ""}
          afterSubmit={afterSubmit}
        />
        {Util.cancelCloseTreeTrimTypes.includes(jobDetail?.jobType ?? "") && (
          <>
            <TreeTrimCancelCloseModal
              action="Cancel"
              job={jobDetail!}
              afterSubmit={() => history.push("/list")}
            />
            <TreeTrimCancelCloseModal
              action="Close"
              job={jobDetail!}
              afterSubmit={() => history.push("/list")}
            />
          </>
        )}
        {Util.closeDamageAssessJobTypes.includes(jobDetail?.jobType ?? "") &&
          (jobDetail?.customerOut.toString() === "1" ||
            jobDetail?.customerOut === "") && (
            <CancelCloseModal
              action="Close"
              job={jobDetail!}
              afterSubmit={() => history.push("/list")}
              jobType={"DamageAssess"}
            />
          )}
        {Util.closeNonOutageJobTypes.includes(jobDetail?.jobType ?? "") && (
          <CancelCloseModal
            action="Close"
            job={jobDetail!}
            afterSubmit={() => history.push("/list")}
            jobType={"NonOutage"}
          />
        )}

        <DamageAssessmentFormModal
          job={jobDetail!}
          afterSubmit={() => getJobDetail()}
        />
      </div>

      <div className="border-l border-r border-t border-gray-300 mb-0.5">
        <Row label="Location" value={jobDetail?.address} />
        <Row label="Meter Id" value={jobDetail?.meterId} />
        <Row label="Job Type" value={jobDetail?.jobType} />
        <Row
          label="Job Status"
          value={
            Util.jobStatusOptions.find((x) => x.value === jobDetail?.jobStatus)
              ?.label ?? "Unknown Status"
          }
        />
        {Util.DAJobTypesForEditableSubType.includes(
          jobDetail?.jobType ?? ""
        ) ? (
          <Row label="Job Sub Type">
            <div
              className={`px-1 py-0.5 text-center cursor-pointer 
              ${
                Util.getJobSubTypeOptionsForDamageAssessJobs(
                  jobDetail?.jobType || ""
                ).find((x) => x.value === jobDetail!.jobSubType)
                  ? "text-green-600"
                  : "text-red-600"
              }${" "}
              ${jobDetail?.jobSubType !== "??" ? "" : "border-2 border-red-700"}
            `}
              onClick={(e) => e.stopPropagation()}
            >
              {jobDetail ? (
                <JobSubTypeModal
                  job={jobDetail}
                  afterSubmit={afterSubmit}
                  showTextArea={true}
                  jobSubTypeOptions={Util.getJobSubTypeOptionsForDamageAssessJobs(
                    jobDetail.jobType || ""
                  )}
                />
              ) : null}
            </div>
          </Row>
        ) : jobDetail?.jobType === "TT" || jobDetail?.jobType === "TRWIRE" ? (
          <>
            <Row label="Job Sub Type">
              <div
                className={`px-1 py-0.5 text-center cursor-pointer 
              ${
                Util.TTJobSubTypeOptions.find(
                  (x) => x.value === jobDetail.jobSubType
                )
                  ? "text-green-600"
                  : "text-red-600"
              }${" "}
              ${jobDetail?.jobSubType !== "??" ? "" : "border-2 border-red-700"}
            `}
                onClick={(e) => e.stopPropagation()}
              >
                {jobDetail ? (
                  <JobSubTypeModal
                    showTextArea={false}
                    jobSubTypeOptions={Util.TTJobSubTypeOptions}
                    job={jobDetail}
                    afterSubmit={afterSubmit}
                  />
                ) : null}
              </div>
            </Row>
            <Row label="Call Src." value={jobDetail?.callSource} />
            <Row label="Name" value={jobDetail?.name} />
            <Row label="Phone">
              <a
                href={`tel:${jobDetail.phoneNumber}`}
                className="text-dte-500 underline"
              >
                {jobDetail.phoneNumber}
              </a>
            </Row>
          </>
        ) : (
          <Row label="Job Sub Type" value={jobDetail?.jobSubType} />
        )}
        <Row
          label="Circuit"
          value={jobDetail?.circuit !== "" ? jobDetail?.circuit : "No Circuit"}
        />
        {Util.OutageJobs.includes(jobDetail?.jobType ?? "") && (
          <>
            <Row label="Cust Out" value={jobDetail?.customerOut} />
            <AMIRows amiInfo={amiData} />
            <Row label="Cust Calls" value={jobDetail?.numOfCalls} />
            <Row label="PRNs" value={jobDetail?.prn_flag} />
          </>
        )}
        <Row label="ERT">
          <div
            className="py-0.5 text-center cursor-pointer"
            onClick={(e) => e.stopPropagation()}
          >
            {jobDetail ? (
              <ERTModal job={jobDetail} afterSubmit={afterSubmit} />
            ) : null}
          </div>
        </Row>
        <Row label="Cause Code">
          <div
            className="py-0.5 text-center cursor-pointer"
            onClick={(e) => e.stopPropagation()}
          >
            {jobDetail ? (
              <CauseCodeModal job={jobDetail} afterSubmit={afterSubmit} />
            ) : null}
          </div>
        </Row>
      </div>
      {jobDetail?.crew && jobDetail.crew.length > 0 && (
        <>
          <h2 className="text-xl mb-1 mt-2.5">Crew Assignments</h2>
          <div className="border-l border-r border-t border-gray-300 mb-0.5">
            {jobDetail.crew.map(
              (x: { crewId: string; crewAssignmentStatus: string }) =>
                x.crewId === "" && x.crewAssignmentStatus === "" ? null : (
                  <Row label={x.crewId} key={x.crewId}>
                    <div
                      className={`py-0.5 text-center 
                      ${
                        x?.crewAssignmentStatus !== ""
                          ? "text-black"
                          : "text-red-600"
                      }
                      `}
                    >
                      <CrewAssignStatusModal
                        job={jobDetail}
                        crewAssignmentStatus={x.crewAssignmentStatus}
                        crewId={x.crewId}
                        afterSubmit={afterSubmit}
                        disabled={
                          role?.tag !== "employee" &&
                          !role?.crews.includes(x.crewId)
                        }
                      />
                    </div>
                  </Row>
                )
            )}
          </div>
        </>
      )}
      <div className="w-full mb-1.5 mt-2.5 inline-flex">
        <button
          className={`${
            currentTab === "Comments" ? "border-b-2 border-blue-500" : ""
          } pb-0.5 mr-3 text-xl`}
          onClick={() => setCurrentTab("Comments")}
        >
          Comments
        </button>
        <button
          className={`${
            currentTab === "Job History" ? "border-b-2 border-blue-500" : ""
          } pb-0.5 mr-3 text-xl`}
          onClick={() => setCurrentTab("Job History")}
        >
          Job History
        </button>
        {isTreeTrimJob() ? (
          <>
            <button
              className={`${
                currentTab === "Activity" ? "border-b-2 border-blue-500" : ""
              } pb-0.5 mr-3 text-xl`}
              onClick={() => setCurrentTab("Activity")}
            >
              Activity
            </button>
            <button
              className={`${
                currentTab === "Outage Info" ? "border-b-2 border-blue-500" : ""
              } pb-0.5 mr-3 text-xl`}
              onClick={() => setCurrentTab("Outage Info")}
            >
              Outage Info
            </button>
          </>
        ) : (
          <></>
        )}
      </div>

      {renderCurrentTab(currentTab)}
    </div>
  );
};

type SortKey = keyof HistoricJob;

type SortDir = "asc" | "desc";

const TableHeader = (props: {
  value: SortKey;
  label: String;
  onClick: (key: SortKey) => void;
  sortKey: SortKey | null;
  sortDir: SortDir;
}) => (
  <th
    scope="col"
    className="px-0.25 md:px-1 py-0.5 whitespace-no-wrap"
    onClick={() => props.onClick(props.value)}
  >
    {props.label}

    <FontAwesomeIcon
      className={`ml-0.5 ${
        props.value === props.sortKey ? "opacity-100" : "opacity-0"
      } `}
      icon={props.sortDir === "asc" ? faCaretUp : faCaretDown}
    />
  </th>
);

const sortByHeader = (
  key: SortKey | null,
  dir: SortDir,
  data: HistoricJob[]
) => {
  if (key !== null) {
    switch (dir) {
      case "asc":
        const ascList = [...data].sort((a, b) => (a[key]! < b[key]! ? 1 : -1));
        return ascList;
      case "desc":
        const descList = [...data].sort((a, b) => (a[key]! > b[key]! ? 1 : -1));
        return descList;
    }
  } else {
    return data;
  }
};

type AMINotFound = { tag: "notFound" };
const nothingYet = (): AMINotFound => ({ tag: "notFound" });
type AMIOk = { tag: "ok"; res: AMIJobStatus };
type AMIError = { tag: "err" };
type AMIInfo = AMIOk | AMINotFound | AMIError;

const AMIRows = (props: { amiInfo: AMIInfo }): JSX.Element => {
  switch (props.amiInfo.tag) {
    case "ok":
      return (
        <>
          <Row
            label="Customers Affected"
            value={
              <div className={"text-red-600"}>{props.amiInfo.res.affected}</div>
            }
          />
          <Row
            label="Customers Restored"
            value={
              <div className={"text-green-600"}>
                {props.amiInfo.res.restored}
              </div>
            }
          />
          <Row
            label="Customers Reported"
            value={
              <div className={"text-red-600"}>{props.amiInfo.res.powerOut}</div>
            }
          />
        </>
      );
    case "notFound":
      return (
        <>
          <Row label="Customers Affected" value={"N/A"} />
          <Row label="Customers Restored" value={"N/A"} />
          <Row label="Customers Reported" value={"N/A"} />
        </>
      );
    case "err":
      return (
        <>
          <Row label="Customers Affected" value={"N/A"} />
          <Row label="Customers Restored" value={"N/A"} />
          <Row label="Customers Reported" value={"N/A"} />
        </>
      );
  }
};
const Comment: React.FC<{ comment: SingleComment }> = ({ comment }) => {
  const renderComment = () => {
    if (comment.comment.startsWith("https")) {
      return (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={comment.comment}
          className="underline text-dte-600"
        >
          PHOTO: {comment.comment.split("/").pop()}
        </a>
      );
    } else if (comment.comment.startsWith("OS_DA_")) {
      const decoded = he.decode(comment.comment);
      const json = decoded.split("OS_DA_")[1];
      try {
        const da = JSON.parse(json) as
          | DamageAssessmentComment
          | DamageAssessmentOnOnArrivalComment;
        return (
          <div className="flex flex-col">
            <p>OS_DA_</p>
            <p>NAME: {da.name.toUpperCase()}</p>
            <p>USER ID: {da.userId.toUpperCase()}</p>
            <p>OK ON ARRIVAL?: {da.okOnArrival ? Util.YES : Util.NO}</p>
            {da.okOnArrival ? (
              <p>NOTES: {da.notes.toUpperCase()}</p>
            ) : (
              <>
                <p>NUMBER OF BROKEN POLES: {da.numberOfBrokenPoles}</p>
                <p>
                  ARE POLES ORDERED?: {da.arePolesOrdered ? Util.YES : Util.NO}
                </p>
                <p>NUMBER OF BROKEN CROSS ARMS: {da.brokenCrossArms}</p>
                <p>SPAN OF WIRES DOWN: {da.spansWireDown}</p>
                <p>
                  IS THERE AN OIL SPILL?: {da.isOilSpill ? Util.YES : Util.NO}
                </p>
                <p>
                  VOLTAGE: {da.voltage.map((x) => x.toUpperCase()).join(", ")}
                </p>
                <p>PIECES OF DAMAGED EQUIPMENT: {da.damagedEquipmentCount}</p>
                <p>
                  LOCATION OF DAMAGE: {da.damageLocation?.toUpperCase() ?? ""}
                </p>
                <p>
                  TRUCK ACCESSIBLE: {da.truckAccessible ? Util.YES : Util.NO}
                </p>
                <p>TREE TRIM: {da.treeTrim?.toUpperCase() ?? ""}</p>
                <p>
                  SITE ACCESSIBLITY: {da.siteAccessibility?.toUpperCase() ?? ""}
                </p>
                <p>
                  SITE ACCESSIBILITY NOTES:{" "}
                  {da.siteAccessibilityNotes.toUpperCase() ?? ""}
                </p>
                {da.brokenPolesLocations.length > 0 ? (
                  <div className="flex flex-col">
                    <p>LOCATIONS OF BROKEN POLES:</p>
                    <ul className="ml-1 list-disc">
                      {da.brokenPolesLocations.map((bp, i) => (
                        <li key={i}>
                          <p>POLE {i + 1}:</p>
                          <ul className="ml-1 list-disc">
                            <li>
                              <a
                                className="underline text-dte-500"
                                href={bp.link}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                VIEW ON MAP
                              </a>
                            </li>
                            {bp.notes.trim() ? (
                              <li>{bp.notes.toUpperCase()}</li>
                            ) : (
                              <></>
                            )}
                          </ul>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <p>LOCATIONS OF BROKEN POLES: NONE</p>
                )}
                {da.crossArmsLocations.length > 0 ? (
                  <div className="flex flex-col">
                    <p>LOCATIONS OF BROKEN CROSS ARMS:</p>
                    <ul className="ml-1 list-disc">
                      {da.crossArmsLocations.map((ca, i) => (
                        <li key={i}>
                          <p>CROSS ARM {i + 1}:</p>
                          <ul className="ml-1 list-disc">
                            <li>
                              <a
                                className="underline text-dte-500"
                                href={ca.link}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                VIEW ON MAP
                              </a>
                            </li>
                            {ca.notes.trim() ? (
                              <li>{ca.notes.toUpperCase()}</li>
                            ) : (
                              <></>
                            )}
                          </ul>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <p>LOCATIONS OF BROKEN CROSS ARMS: NONE</p>
                )}
                {da.photos.length > 0 ? (
                  <div className="flex flex-col">
                    <p>PHOTOS:</p>
                    <ul className="ml-1 list-disc">
                      {da.photos.map((p, i) => (
                        <li key={i}>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={p}
                            className="underline text-dte-600"
                          >
                            {p.split("/").pop()}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <p>PHOTOS: NONE</p>
                )}
                <p>NOTES: {da.notes.toUpperCase()}</p>
              </>
            )}
          </div>
        );
      } catch (e) {
        console.log(e);
        return <p>OS_DA_ Error parsing comment details</p>;
      }
    } else {
      const decoded = he.decode(comment.comment);
      return <p>{decoded}</p>;
    }
  };

  return (
    <div className="inline-flex flex-no-wrap">
      <div className="flex flex-no-wrap">
        <p className="whitespace-no-wrap w-9.25">
          {comment.timestamp &&
            new Date(comment.timestamp).toLocaleString(...Util.dateFormat)}
        </p>
        <p className="w-1">:</p>
      </div>

      {renderComment()}
    </div>
  );
};

const JobCommentsList = (props: {
  jobDetail: JobDetail | null;
  role: Util.Role | null;
  getJobDetail: () => void;
}) => {
  const [enteredComment, setEnteredComment] = React.useState("");
  const dispatch = useAppDispatch();
  const role = useAppSelector((state) => state.role.value);

  const saveComment = async () => {
    dispatch(setLoading(true));
    if (props.jobDetail) {
      await trackLocationData("updateJob", props.jobDetail.jobId, role!);

      const res = await updateJob(props.jobDetail?.jobId, {
        msgId: props.jobDetail.jobId,
        jobComments: `${props.role?.name ?? ""} : ${enteredComment}`,
      });
      if (res.tag === "ok") {
        setEnteredComment("");
        setTimeout(props.getJobDetail, 1500);
      } else {
        alert(
          "Job update failed, check your internet connection. You can resend the request through the Drafts page"
        );
      }
    }
    dispatch(setLoading(false));
  };
  return (
    <div className="flex flex-col justify-center mb-1">
      <textarea
        placeholder="Add New Comment"
        value={enteredComment}
        className="border border-gray-300 mb-1 h-2.75 focus:h-6 w-full mx-auto p-0.5"
        onChange={(e: any) => setEnteredComment(e.target.value)}
      ></textarea>
      {enteredComment.length > 0 && (
        <button
          className="bg-dte-500 py-0.25 px-1 text-white w-7 mx-auto mb-1"
          onClick={saveComment}
        >
          Save Comment
        </button>
      )}
      {props.jobDetail &&
        ("comments" in props.jobDetail.comments ? (
          Array.isArray(props.jobDetail.comments.comments) ? (
            props.jobDetail.comments.comments
              .sort(Util.sortCommentsByDate)
              .map((x, i) => <Comment comment={x} key={i} />)
          ) : (
            <Comment comment={props.jobDetail.comments.comments} />
          )
        ) : "comment" in props.jobDetail?.comments &&
          props.jobDetail?.comments?.comment !== "" &&
          props.jobDetail?.comments?.timestamp !== "" ? (
          <Comment comment={props.jobDetail.comments} />
        ) : null)}
    </div>
  );
};
const JobHistoryList = (props: {
  setSort: any;
  sortKey: SortKey | null;
  sortDir: SortDir;
  historicJobs: HistoricJob[];
  jobHistoryError: boolean;
}) => {
  if (props.jobHistoryError) {
    return (
      <div className="w-full flex flex-wrap max-h-screen h-full overflow-x-auto">
        Error getting job history
      </div>
    );
  }

  if (props.historicJobs.length === 0) {
    return (
      <div className="w-full flex flex-wrap max-h-screen h-full overflow-x-auto">
        No job history found
      </div>
    );
  }

  return (
    <div className="w-full flex flex-wrap max-h-screen h-full overflow-x-auto">
      <table className="table-auto bg-white w-full text-black border mb-2">
        <thead className="bg-gray-200">
          <tr className="cursor-pointer">
            <TableHeader
              value="jobId"
              label="Job"
              onClick={props.setSort}
              sortKey={props.sortKey}
              sortDir={props.sortDir}
            />
            <TableHeader
              value="jobType"
              label="Type"
              onClick={props.setSort}
              sortKey={props.sortKey}
              sortDir={props.sortDir}
            />
            <TableHeader
              value="causeCode"
              label="Cause Code"
              onClick={props.setSort}
              sortKey={props.sortKey}
              sortDir={props.sortDir}
            />
            <TableHeader
              value="circuit"
              label="Circuit"
              onClick={props.setSort}
              sortKey={props.sortKey}
              sortDir={props.sortDir}
            />
            <TableHeader
              value="outageDate"
              label="Outage Date"
              onClick={props.setSort}
              sortKey={props.sortKey}
              sortDir={props.sortDir}
            />
            <TableHeader
              value="RestorationDate"
              label="Restoration Date"
              onClick={props.setSort}
              sortKey={props.sortKey}
              sortDir={props.sortDir}
            />
          </tr>
        </thead>
        <tbody>
          {sortByHeader(props.sortKey, props.sortDir, props.historicJobs).map(
            (job, i) => (
              <HistoricJobModal jobs={props.historicJobs} index={i} key={i} />
            )
          )}
        </tbody>
      </table>
    </div>
  );
};

export default withRouter(DetailsPage);
