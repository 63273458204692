import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import Popup from "reactjs-popup";
import * as api from "../../lib/Api";
import { setLoading } from "../../features/jobSlice";
import { useAppSelector, useAppDispatch } from "../../lib/hooks";
import { trackLocationData } from "../../lib/api/trackLocationApi";

const UploadPhotoModal = (props: { jobId: string; afterSubmit: Function }) => {
  const dispatch = useAppDispatch();
  const role = useAppSelector((state) => state.role.value);
  const [image, setImage] = useState<{ preview: string; raw: null | File }>({
    preview: "",
    raw: null,
  });

  const [submitting, setSubmitting] = React.useState(false);

  const handleChange = (e: any) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };

  const handleSubmit = async () => {
    setSubmitting(true);
    if (image.raw) {
      const img = new File([image.raw], `${props.jobId}-${Date.now()}.png`, {
        type: "image/png",
      });
      const res = await api.uploadImage(img);
      if (res.tag === "ok") {
        await trackLocationData("updateJob", props.jobId, role!);

        const updateRes = await api.updateJob(props.jobId, {
          msgId: props.jobId,
          photo: res.data.message,
          jobComments: `${role?.name ?? ""} uploaded photo: ${
            res.data.message
          }`,
        });
        if (updateRes.tag === "ok") {
          props.afterSubmit();
        } else {
          alert("Error uploading image, check your internet connection.");
        }
      } else {
        alert("Error uploading image, check your internet connection.");
        dispatch(setLoading(false));
      }
    }
    setSubmitting(false);
  };

  const closeWrapper = (close: () => void) => {
    setImage({ preview: "", raw: null });
    close();
  };

  return (
    <>
      <Popup
        closeOnDocumentClick={true}
        modal={true}
        overlayStyle={{ cursor: "default" }}
        contentStyle={{ cursor: "default" }}
        className="responsive-modal"
        trigger={
          <button className="mr-0.5 mb-0.25 bg-dte-500 py-0.25 px-0.5 text-white">
            Upload Photo
          </button>
        }
      >
        {(close) => (
          <div className="text-black py-1">
            <p className="mb-1 text-center">Upload Photo</p>
            <div className="w-full inline-flex flex-no-wrap justify-center items-center">
              <input
                type="file"
                name="uploadedImage"
                accept=".png, .jpg, .jpeg"
                className="my-1 p-0.5"
                onChange={handleChange}
              />
            </div>
            {image.preview ? (
              <div className="w-full inline-flex justify-center items-center">
                <img
                  src={image.preview}
                  alt="preview"
                  width="300"
                  height="300"
                />
              </div>
            ) : null}
            <div className="w-full inline-flex flex-no-wrap justify-center items-center my-1">
              {submitting ? (
                <>
                  <FontAwesomeIcon className="mt-0.25" icon={faSpinner} spin />
                  <p className="ml-0.25">Submitting...</p>
                </>
              ) : (
                <>
                  <button
                    className="px-1 py-0.5 bg-gray-200 font-bold mr-1"
                    onClick={() =>
                      handleSubmit().then(() => closeWrapper(close))
                    }
                  >
                    Submit
                  </button>
                  <button
                    className="px-1 py-0.5 bg-gray-200 font-bold ml-1"
                    onClick={() => closeWrapper(close)}
                  >
                    Cancel
                  </button>
                </>
              )}
            </div>
          </div>
        )}
      </Popup>
    </>
  );
};

export default UploadPhotoModal;
