import React from "react";
import Nav from "./components/Nav";
import {
  Switch,
  Route,
  Redirect,
  withRouter,
  useHistory,
} from "react-router-dom";
import JobList, {
  SORT_DIR_LOCAL_STORAGE_KEY,
  SORT_KEY_LOCAL_STORAGE_KEY,
} from "./pages/JobList";
import JobDetails from "./pages/JobDetails";
import Map from "./pages/Map";
import Outages from "./pages/Outages";
import Settings from "./pages/Settings";
import Crews from "./pages/Crews";
import Notifications from "./pages/Notifications";
import msalInstance, { renewRequest, userRequest } from "./auth/authProvider";
import * as Util from "./lib/Utility";
import * as api from "./lib/Api";
import Drafts from "./pages/Drafts";
import { useAppSelector, useAppDispatch } from "./lib/hooks";
import { setRole } from "./features/roleSlice";
import Axios, { AxiosError } from "axios";
import {
  EXCLUDE_FOLLOW_UP_JOBS_LOCALSTORAGE_KEY,
  getJobs,
  getJobsByCrews,
  JOB_FILTER_LOCALSTORAGE_KEY,
  SERVICE_CENTERS_LOCALSTORAGE_KEY,
  setExcludeFollowUpJobs,
  setJobFilter,
  setServiceCenter,
  setShowCrewJobs,
  SHOW_CREW_JOBS_LOCALSTORAGE_KEY,
} from "./features/jobSlice";
import { setOnline } from "./features/onlineSlice";
import { getOutageCounts } from "./features/outageCountsSlice";
import { addDraft, setDrafts } from "./features/draftsSlice";
import { DRAFTS_LOCALSTORAGE_KEY } from "./lib/ReduxMiddleware";
import CrewNotificationPopup from "./components/Notifications/CrewNotificationPopup";
import {
  getCrewAssignmentNotifications,
  getSignalRConfig,
} from "./features/crewAssignmentNotificationsSlice";
import { trackLocationData } from "./lib/api/trackLocationApi";
import clsx from "clsx";
import WorkOrders from "./pages/WorkOrders";

const OSA_VERSION_LOCALSTORAGE_KEY = "OSA.VERSION";

//App
const App = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const role = useAppSelector((state) => state.role.value);
  const [loginState, setLoginState] = React.useState<
    "start" | "pending" | "resolved" | "error" | "rejected"
  >("start");

  React.useEffect(() => {
    const acc = msalInstance.getAccount();
    if (acc) {
      triggerLogin();
    }
  }, []);

  React.useEffect(() => {
    const unlisten = history.listen(async () => {
      if (!role) return;
      await trackLocationData("pageNav", history.location.pathname, role!);
    });

    return () => {
      unlisten();
    };
  });

  React.useEffect(() => {
    (async () => {
      if (role) {
        await trackLocationData("login", role.id, role);
      }
    })();
  }, [role]);

  // fetch items from local storage to populate some of our global state.
  React.useEffect(() => {
    const currentVersion = process.env.REACT_APP_VERSION;
    const oldVersion = Util.getFromLocalStorageOrDefault(
      OSA_VERSION_LOCALSTORAGE_KEY,
      ""
    );

    if (currentVersion !== oldVersion) {
      localStorage.removeItem(SERVICE_CENTERS_LOCALSTORAGE_KEY);
      localStorage.removeItem(DRAFTS_LOCALSTORAGE_KEY);
      localStorage.removeItem(JOB_FILTER_LOCALSTORAGE_KEY);
      localStorage.removeItem(EXCLUDE_FOLLOW_UP_JOBS_LOCALSTORAGE_KEY);
      localStorage.removeItem(SHOW_CREW_JOBS_LOCALSTORAGE_KEY);
      localStorage.removeItem(SORT_DIR_LOCAL_STORAGE_KEY);
      localStorage.removeItem(SORT_KEY_LOCAL_STORAGE_KEY);

      //set new version
      localStorage.setItem(
        OSA_VERSION_LOCALSTORAGE_KEY,
        JSON.stringify(currentVersion)
      );
    }
    console.log("Current App Version: " + currentVersion);

    const serviceCenters =
      Util.getFromLocalStorageOrDefault<Util.ServiceCenterSelection>(
        SERVICE_CENTERS_LOCALSTORAGE_KEY,
        Util.ALL_SERVICE_CENTER
      );
    dispatch(setServiceCenter(serviceCenters));

    const drafts = Util.getFromLocalStorageOrDefault(
      DRAFTS_LOCALSTORAGE_KEY,
      []
    );

    dispatch(setDrafts(drafts));

    const jobFilter = Util.getFromLocalStorageOrDefault<Util.JobTypeFilter>(
      JOB_FILTER_LOCALSTORAGE_KEY,
      "All"
    );
    dispatch(setJobFilter(jobFilter as Util.JobTypeFilter));

    const excludeFollowUpJobs = Util.getFromLocalStorageOrDefault(
      EXCLUDE_FOLLOW_UP_JOBS_LOCALSTORAGE_KEY,
      true
    );
    dispatch(setExcludeFollowUpJobs(excludeFollowUpJobs));

    if (role) {
      if (role.overrides?.tag !== "employee" && role?.tag === "contractor") {
        dispatch(setShowCrewJobs(true));
      } else if (role?.crews.length > 0) {
        const showCrewJobs = Util.getFromLocalStorageOrDefault(
          SHOW_CREW_JOBS_LOCALSTORAGE_KEY,
          true
        );
        dispatch(setShowCrewJobs(showCrewJobs));
      } else {
        dispatch(setShowCrewJobs(false));
      }
      dispatch(getCrewAssignmentNotifications());
      dispatch(getSignalRConfig());
    }
  }, [dispatch, role]);

  const triggerLogin = async () => {
    setLoginState("pending");
    const acc = msalInstance.getAccount();
    if (!acc) {
      msalInstance.loginRedirect(userRequest);
    } else {
      try {
        const res = await msalInstance.acquireTokenSilent(renewRequest);

        const getSupervisorFlag = (chief: string | null | undefined) => {
          if (chief) {
            if (typeof chief === "boolean") {
              return chief;
            } else if (typeof chief === "string") {
              if (chief === "true") {
                return true;
              } else {
                return false;
              }
            } else {
              return false;
            }
          } else {
            return false;
          }
        };

        const name = res?.account.idToken?.name;
        const jwt = res?.idToken.rawIdToken;
        const id =
          res?.account.idToken?.onpremisessamaccountname ??
          res?.account.idToken?.preferred_username ??
          "";
        const tag =
          !res?.account.idToken?.jobtitle ||
          res?.account.idToken?.jobtitle?.startsWith("NonEE")
            ? "contractor"
            : "employee";

        const tt =
          res?.account.idTokenClaims?.department?.startsWith("DO - TT") ??
          false;

        const cRes = await api.getUserDetails(id);

        if (tag === "employee") {
          if (cRes.tag === "ok" && cRes.data) {
            dispatch(
              setRole({
                name,
                jwt,
                id,
                crews: cRes.data?.crew ?? [],
                foreman: cRes.data ? cRes.data.crew.length > 1 : false,
                tt: cRes.data?.options.treeTrim || tt,
                tag: cRes.data?.options.tag || tag,
                supervisor:
                  cRes.data?.options.supervisor ||
                  getSupervisorFlag(res?.account.idTokenClaims?.dteChief),
              })
            );

            setLoginState("resolved");
          } else {
            dispatch(
              setRole({
                name,
                jwt,
                id,
                crews: [],
                foreman: false,
                tt: tt,
                tag: tag,
                supervisor: getSupervisorFlag(
                  res?.account.idTokenClaims?.dteChief
                ),
              })
            );
            setLoginState("resolved");
            console.log(cRes);
          }
        } else {
          if (cRes.tag === "ok") {
            if (cRes.data && cRes.data.active) {
              dispatch(
                setRole({
                  name,
                  jwt,
                  id,
                  crews: cRes.data.crew,
                  foreman: cRes.data.crew.length > 1,
                  tt: cRes.data.options.treeTrim || tt,
                  tag: cRes.data.options.tag || tag,
                  supervisor:
                    cRes.data.options.supervisor ||
                    getSupervisorFlag(res?.account.idTokenClaims?.dteChief),
                })
              );
              setLoginState("resolved");
            } else {
              setLoginState("rejected");
            }
          } else {
            setLoginState("error");
          }
        }
      } catch (err) {
        console.log(err);
        msalInstance.loginRedirect(userRequest);
      }
    }
  };

  if (loginState === "pending") return <Authenticating />;
  if (loginState === "rejected") return <Rejected />;
  if (loginState === "error") return <ErrorAuthenticating />;

  return <>{role ? <ProtectedRoutes /> : <Login login={triggerLogin} />}</>;
};

export default withRouter(App);

const ProtectedRoutes = () => {
  const role = useAppSelector((state) => state.role.value);
  const serviceCenter = useAppSelector((state) => state.jobs.serviceCenter);
  const jobFilter = useAppSelector((state) => state.jobs.jobFilter);
  const showCrewJobs = useAppSelector((state) => state.jobs.showCrewJobs);
  const online = useAppSelector((state) => state.online.value);
  const loading = useAppSelector((state) => state.jobs.loading);
  const [bottom, setBottom] = React.useState(false);
  const dispatch = useAppDispatch();
  const noDraftUrls = ["/Job/Filter", "/api/trackLocation"];

  const shouldTrackDraft = (draftErrorUrl: string) => {
    const match = noDraftUrls.find((url) => {
      if (draftErrorUrl.includes(url)) {
        return true;
      }
    });

    return match === undefined;
  };

  React.useEffect(() => {
    Axios.interceptors.response.use(
      (res) => res,
      (error) => {
        const err: AxiosError = error.toJSON();

        if (
          ["patch", "PATCH", "POST", "post"].includes(
            err.config.method ?? ""
          ) &&
          shouldTrackDraft(err.config.url || "")
        )
          dispatch(
            addDraft({
              id: Util.genId(),
              url: err.config.url ?? "",
              data: JSON.parse(err.config.data ?? "{}"),
            })
          );
        return Promise.reject(error);
      }
    );
  }, []);

  React.useEffect(() => {
    if (online) {
      if (showCrewJobs) {
        dispatch(getJobsByCrews());
      } else {
        dispatch(getJobs());
      }
    }
  }, [online, serviceCenter, role, showCrewJobs, jobFilter]);

  React.useEffect(() => {
    window.addEventListener("offline", () => {
      dispatch(setOnline(false));
    });
    window.addEventListener("online", () => {
      dispatch(setOnline(true));
    });
  }, []);

  React.useEffect(() => {
    if (role?.tag === "employee") {
      dispatch(getOutageCounts());
    }
  }, [role?.tag, role?.jwt]);

  const logout = async () => {
    if (role) {
      await trackLocationData("logout", role.id, role!);
    }
    msalInstance.logout();
  };

  const checkBottom = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const bottom =
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop <=
      e.currentTarget.clientHeight;
    setBottom(bottom);
  };

  return (
    <div
      className={clsx(
        "w-screen h-screen flex flex-col overflow-hidden vh-work-around",
        loading ? "wait-cursor" : ""
      )}
    >
      <Nav />
      <div
        onScroll={checkBottom}
        className="w-full h-full max-h-full flex-grow overflow-y-auto bg-white relative"
      >
        {role?.tag === "employee" ? (
          <Switch>
            <Route
              path="/"
              exact
              render={() => {
                if ((role?.crews?.length ?? 0) > 0) {
                  return <Redirect to="/list" />;
                } else {
                  return <Redirect to="/map" />;
                }
              }}
            />
            <Route path="/map" exact component={Map} />
            <Route
              path="/list"
              exact
              render={() => <JobList bottom={bottom} />}
            />
            <Route path="/details/:id" exact component={JobDetails} />
            <Route path="/outages" exact component={Outages} />
            <Route path="/settings" exact component={Settings} />
            <Route path="/notifications" exact component={Notifications} />
            <Route path="/drafts" exact component={Drafts} />
            <Route path="/workorders" exact component={WorkOrders} />
            {role.foreman && <Route path="/crews" exact component={Crews} />}
          </Switch>
        ) : (
          <Switch>
            <Route path="/" exact render={() => <Redirect to="/list" />} />
            <Route path="/list" exact component={JobList} />
            <Route path="/details/:id" exact component={JobDetails} />
            <Route path="/drafts" exact component={Drafts} />
            {role?.foreman && <Route path="/crews" exact component={Crews} />}
            <Route path="/notifications" exact component={Notifications} />
            <Route path="/workorders" exact component={WorkOrders} />
          </Switch>
        )}
      </div>
      <div className="text-center p-0.5 m-auto">
        {role && (
          <span className="mr-2 text-center">Welcome, {role.name}!</span>
        )}
        <button className="text-center" onClick={logout}>
          Logout
        </button>
        <CrewNotificationPopup />
      </div>
    </div>
  );
};

const Login = (props: { login: () => void }) => {
  return (
    <div className="bg-dte-500 w-3/4 mx-auto flex flex-col items-center mt-5">
      <h1 className="text-5xl mb-3 text-center">Outage Status</h1>
      <button
        onClick={props.login}
        className="w-22 text-lg px-4 py-1 border border-white rounded max-w-full"
      >
        Get Started
      </button>
    </div>
  );
};

const Authenticating = () => (
  <div className="bg-dte-500 w-3/4 mx-auto flex flex-col items-center mt-5">
    <h1 className="text-5xl mb-3 text-center">Authenticating...</h1>
  </div>
);

const ErrorAuthenticating = () => (
  <div className="bg-dte-500 w-3/4 mx-auto flex flex-col items-center mt-5">
    <h1 className="text-5xl mb-3 text-center">
      Error authenticating, please refresh and try again
    </h1>
  </div>
);

const Rejected = () => (
  <div className="bg-dte-500 w-3/4 mx-auto flex flex-col items-center mt-5">
    <h1 className="text-5xl mb-3 text-center">
      You must have a crew assigned to be able to access this app
    </h1>
  </div>
);
