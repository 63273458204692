import React from "react";
import Popup from "reactjs-popup";
import "react-datepicker/dist/react-datepicker.css";
import {
  JobDetailShort,
  JobDetail,
  updateJob,
  JobUpdateReq,
} from "../../lib/Api";
import { useAppSelector, useAppDispatch } from "../../lib/hooks";
import { setLoading } from "../../features/jobSlice";
import DamageAssessment, {
  DamageAssessmentComment,
  DamageAssessmentLocation,
  DamageAssessmentOkOnArrival,
  DamageLocation,
  DISTRIBUTION,
  EASEMENT_BACKYARD,
  EXTREME,
  getDAForm,
  HEAVY,
  ISLAND,
  LIGHT,
  NONE,
  POOR_FIELD_CONDITIONS,
  POOR_ROAD_CONDITIONS,
  saveDAForm,
  SECONDARY,
  SIDE_OF_ROAD,
  SiteAccessibility,
  STANDARD,
  submitDAForm,
  SUB_TRANSMISSION,
  SWAMP,
  TreeTrim,
  Voltage,
} from "../../lib/api/damageAssessmentApi";
import Row from "../Row";
import clsx from "clsx";
import { TextAreaControl } from "../controls/TextAreaControl";
import { MultiCheckControl } from "../controls/MultiCheckControl";
import { RadioControl } from "../controls/RadioControl";
import { NumericControl } from "../controls/NumericControl";
import { PhotoUploadControl } from "../controls/PhotoUploadControl";
import { getYesNoRadioOptionValue } from "../../lib/Utility";
import DAMap from "../DAMap";
import { trackLocationData } from "../../lib/api/trackLocationApi";

type DAForm = {
  okOnArrival: boolean;
  notes: string;
  numberOfBrokenPoles: number;
  arePolesOrdered: boolean | null;
  brokenCrossArms: number;
  spansWireDown: number;
  isOilSpill: boolean | null;
  voltage: Voltage[];
  damagedEquipmentCount: number;
  damageLocation: DamageLocation | null;
  truckAccessible: boolean | null;
  treeTrim: TreeTrim | null;
  siteAccessibility: SiteAccessibility | null;
  siteAccessibilityNotes: string;
  brokenPolesLocations: DamageAssessmentLocation[];
  crossArmsLocations: DamageAssessmentLocation[];
  photos: string[];
};

type DAKey = keyof DAForm;

const defaultDAForm: DAForm = {
  okOnArrival: false,
  notes: "",
  numberOfBrokenPoles: 0,
  arePolesOrdered: null,
  brokenCrossArms: 0,
  spansWireDown: 0,
  isOilSpill: null,
  voltage: [],
  damagedEquipmentCount: 0,
  damageLocation: null,
  truckAccessible: null,
  treeTrim: null,
  siteAccessibility: null,
  siteAccessibilityNotes: "",
  brokenPolesLocations: [],
  crossArmsLocations: [],
  photos: [],
};

const createDAComment = (
  da: DamageAssessment | DamageAssessmentOkOnArrival,
  userId: string,
  name: string
) => {
  if (da.okOnArrival) {
    const comment = "DA_" + JSON.stringify({ name, userId, ...da });
    return comment;
  } else {
    const brokenPoles = da.brokenPolesLocations.map((x) => ({
      ...x,
      link: `https://maps.google.com/?q=${x.location.lat},${x.location.long}`,
    }));
    const crossArms = da.crossArmsLocations.map((x) => ({
      ...x,
      link: `https://maps.google.com/?q=${x.location.lat},${x.location.long}`,
    }));
    const daComment: DamageAssessmentComment = {
      name,
      userId,
      ...da,
      brokenPolesLocations: brokenPoles,
      crossArmsLocations: crossArms,
    };
    const comment = "DA_" + JSON.stringify(daComment);
    return comment;
  }
};

const DamageAssessmentToDAForm = (da: DamageAssessment): DAForm => {
  return {
    ...da,
    arePolesOrdered: da.arePolesOrdered ?? null,
    isOilSpill: da.isOilSpill ?? null,
    damagedEquipmentCount: da.damagedEquipmentCount ?? 0,
    numberOfBrokenPoles: da.numberOfBrokenPoles ?? 0,
    brokenCrossArms: da.brokenCrossArms ?? 0,
    spansWireDown: da.spansWireDown ?? 0,
    voltage: da.voltage ?? [],
    damageLocation: da.damageLocation ?? null,
    truckAccessible: da.truckAccessible ?? null,
    treeTrim: da.treeTrim ?? null,
    siteAccessibility: da.siteAccessibility ?? null,
    siteAccessibilityNotes: da.siteAccessibilityNotes ?? "",
    brokenPolesLocations: da.brokenPolesLocations ?? [],
    crossArmsLocations: da.crossArmsLocations ?? [],
    photos: da.photos ?? [],
  };
};

type BrokenPoleSelection = "Pole" | "Cross Arm";

const DamageAssessmentFormModal = (props: {
  job: JobDetail | JobDetailShort;
  afterSubmit: Function;
}) => {
  const dispatch = useAppDispatch();
  const role = useAppSelector((state) => state.role.value);
  const [DAForm, setDAForm] = React.useState<DAForm>(defaultDAForm);
  const [formErrors, setFormErrors] = React.useState<DAKey[]>([]);
  const [brokenItemSelection, setBrokenItemSelection] =
    React.useState<BrokenPoleSelection>("Pole");

  React.useEffect(() => {
    if (props.job) {
      fetchDAForm(props.job.jobId);
    }
  }, [props.job]);

  const updateDAForm = <T extends DAKey>(key: T, value: DAForm[T]) => {
    setDAForm((prev) => ({ ...prev, [key]: value }));
  };

  const fetchDAForm = async (jobId: string) => {
    dispatch(setLoading(true));
    const res = await getDAForm(jobId);
    if (res.tag === "ok") {
      if (Object.keys(res.data).length > 0) {
        const form = DamageAssessmentToDAForm(res.data);
        setDAForm(form);
      } else {
        setDAForm(defaultDAForm);
      }
    } else {
      console.log(res.err);
      setDAForm(defaultDAForm);
    }
    dispatch(setLoading(false));
  };

  const checkForm = () => {
    const errors: DAKey[] = [];

    if (DAForm.okOnArrival) {
      if (DAForm.notes.length === 0) {
        errors.push("notes");
      }
    } else {
      if (DAForm.numberOfBrokenPoles > 0 && DAForm.arePolesOrdered === null) {
        errors.push("arePolesOrdered");
      }

      if (DAForm.isOilSpill === null) {
        errors.push("isOilSpill");
      }

      if (DAForm.voltage.length === 0) {
        errors.push("voltage");
      }

      if (DAForm.damageLocation === null) {
        errors.push("damageLocation");
      }

      if (DAForm.truckAccessible === null) {
        errors.push("truckAccessible");
      }
      if (DAForm.treeTrim === null) {
        errors.push("treeTrim");
      }
      if (DAForm.siteAccessibility === null) {
        errors.push("siteAccessibility");
      }
    }

    setFormErrors(errors);
    return errors;
  };

  const saveForm = async () => {
    dispatch(setLoading(true));
    const user = `${role?.name} - ${role?.id}`;
    const timestamp = new Date().toISOString();

    await trackLocationData("saveDamageAssessment", props.job.jobId, role!);

    const res = await saveDAForm({
      ...DAForm,
      jobId: props.job.jobId,
      circuit: props.job.circuit,
      createUser: user,
      createDate: timestamp,
    });
    if (res.tag === "ok") {
      alert("Damage Assessment Form saved successfully");
      fetchDAForm(props.job.jobId);
    } else {
      console.log(res.err);
      alert("Error saving Damage Assessment Form");
    }
    dispatch(setLoading(false));
  };

  const submitForm = async (close: () => void) => {
    dispatch(setLoading(true));
    const errors = checkForm();
    if (errors.length === 0) {
      const user = `${role?.name} - ${role?.id}`;
      const timestamp = new Date().toISOString();
      const daForm = {
        ...DAForm,
        jobId: props.job.jobId,
        circuit: props.job.circuit,
        submittedOn: timestamp,
        submittedBy: user,
      };

      await trackLocationData("submitDamageAssessment", props.job.jobId, role!);

      const res = await submitDAForm(daForm);
      if (res.tag === "ok") {
        const daFormComment: DamageAssessment | DamageAssessmentOkOnArrival =
          DAForm.okOnArrival
            ? {
                jobId: props.job.jobId,
                circuit: props.job.circuit,
                okOnArrival: DAForm.okOnArrival,
                notes: DAForm.notes,
                submittedOn: timestamp,
                submittedBy: user,
              }
            : {
                ...DAForm,
                jobId: props.job.jobId,
                circuit: props.job.circuit,
                submittedOn: timestamp,
                submittedBy: user,
              };
        const comment = createDAComment(
          daFormComment,
          role?.id ?? "",
          role?.name ?? ""
        );
        const jobUpdate: JobUpdateReq = {
          msgId: props.job.jobId,
          jobComments: comment,
        };

        const uRes = await updateJob(props.job.jobId, jobUpdate);
        if (uRes.tag === "ok") {
          //alert("Damage Assessment Form submitted successfully");
          props.afterSubmit();
          close();
        } else {
          alert(
            "Error updating job chronology with Damage Assessment Form details"
          );
        }
      } else {
        console.log(res.err);
        alert("Error submitting Damage Assessment Form");
      }
    } else {
      alert(
        "Please make sure all required fields have been filled out before submitting"
      );
    }
    dispatch(setLoading(false));
  };

  const switchBrokenItemType = (index: number, type: BrokenPoleSelection) => {
    if (type === "Pole") {
      const item = DAForm.brokenPolesLocations[index];
      if (item) {
        setDAForm((prev) => ({
          ...prev,
          brokenPolesLocations: prev.brokenPolesLocations.filter(
            (x, i) => i !== index
          ),
          crossArmsLocations: [...prev.crossArmsLocations, item],
        }));
      }
    } else {
      const item = DAForm.crossArmsLocations[index];
      if (item) {
        setDAForm((prev) => ({
          ...prev,
          crossArmsLocations: prev.crossArmsLocations.filter(
            (x, i) => i !== index
          ),
          brokenPolesLocations: [...prev.brokenPolesLocations, item],
        }));
      }
    }
  };

  return (
    <Popup
      closeOnDocumentClick={false}
      modal={true}
      overlayStyle={{ cursor: "default" }}
      contentStyle={{ cursor: "default" }}
      className="responsive-modal responsive-modal-da-form modal-no-padding vf-work-around"
      onOpen={() => fetchDAForm(props.job.jobId)}
      trigger={
        <button className="mr-0.5 mb-0.25 bg-dte-500 py-0.25 px-0.5 text-white">
          Damage Assessment Form
        </button>
      }
    >
      {(close) => (
        <div className="text-black relative flex flex-col max-h-modal">
          <div className="flex flex-wrap justify-center px-2 mb-5 max-h-full overflow-auto">
            <h1 className="w-full text-2xl p-2.5 text-center">
              Damage Assessment
            </h1>
            <div className="w-full border-l border-r border-t border-gray-300 mb-0.5 px-0.5">
              <Row label="Job Id" value={props.job.jobId} />
              <Row label="Circuit" value={props.job.circuit} />
              <div className="w-full p-1 bg-gray-300 flex flex-col">
                <div className="flex flex-no-wrap justify-start items-center mb-1.5">
                  <input
                    checked={DAForm.okOnArrival}
                    type="checkbox"
                    className="h-1 w-1"
                    onChange={(e) =>
                      setDAForm({
                        ...defaultDAForm,
                        okOnArrival: e.target.checked,
                      })
                    }
                  />
                  <label className="pl-0.5">Ok on Arrival</label>
                </div>
                <div
                  className={clsx(
                    "w-full flex flex-col",
                    !DAForm.okOnArrival && "disabled"
                  )}
                >
                  <TextAreaControl
                    label="Notes"
                    error={formErrors.includes("notes")}
                    value={DAForm.notes}
                    required={false}
                    disabled={!DAForm.okOnArrival}
                    setValue={(value) => updateDAForm("notes", value)}
                  />
                </div>
              </div>
            </div>
            <div
              className={clsx(
                DAForm.okOnArrival && "disabled",
                "mt-3 w-full flex flex-col"
              )}
            >
              <FormRow>
                <NumericControl
                  containerClass="xs:mr-3.125"
                  controlClass="w-11.25"
                  label="Number of Broken Poles"
                  error={formErrors.includes("numberOfBrokenPoles")}
                  value={DAForm.numberOfBrokenPoles}
                  required={false}
                  disabled={DAForm.okOnArrival}
                  setValue={(value) =>
                    updateDAForm("numberOfBrokenPoles", value)
                  }
                />
                <RadioControl
                  label="Have the Poles been ordered"
                  name="arePolesOrdered"
                  error={formErrors.includes("arePolesOrdered")}
                  value={getYesNoRadioOptionValue(DAForm.arePolesOrdered)}
                  options={["Yes", "No"]}
                  required={DAForm.numberOfBrokenPoles > 0}
                  disabled={
                    DAForm.numberOfBrokenPoles === 0 || DAForm.okOnArrival
                  }
                  setValue={(value) =>
                    updateDAForm("arePolesOrdered", value === "Yes")
                  }
                />
              </FormRow>
              <FormRow>
                <NumericControl
                  containerClass="xs:mr-3.125"
                  controlClass="w-11.25"
                  label="Broken Cross Arms"
                  value={DAForm.brokenCrossArms}
                  error={formErrors.includes("brokenCrossArms")}
                  required={false}
                  disabled={DAForm.okOnArrival}
                  setValue={(value) => updateDAForm("brokenCrossArms", value)}
                />

                <NumericControl
                  label="Span of Wires Down"
                  controlClass="w-11.25"
                  error={formErrors.includes("spansWireDown")}
                  value={DAForm.spansWireDown}
                  required={false}
                  disabled={DAForm.okOnArrival}
                  setValue={(value) => updateDAForm("spansWireDown", value)}
                />
              </FormRow>
              <FormRow>
                <RadioControl
                  label="Is there an Oil Spill?"
                  name="isOilSpill"
                  error={formErrors.includes("isOilSpill")}
                  value={getYesNoRadioOptionValue(DAForm.isOilSpill)}
                  options={["Yes", "No"]}
                  required={true}
                  disabled={DAForm.okOnArrival}
                  setValue={(value) =>
                    updateDAForm("isOilSpill", value === "Yes")
                  }
                />
              </FormRow>
              <FormRow>
                <MultiCheckControl
                  label="Voltage"
                  error={formErrors.includes("voltage")}
                  values={DAForm.voltage}
                  options={[SUB_TRANSMISSION, DISTRIBUTION, SECONDARY]}
                  required={true}
                  disabled={DAForm.okOnArrival}
                  setValues={
                    (values) =>
                      updateDAForm(
                        "voltage",
                        values as Voltage[]
                      ) /* this is bad, but I don't want to figure out what TS trickery is need to make React.FC work with generics*/
                  }
                />
              </FormRow>
              <FormRow>
                <NumericControl
                  label="Pieces of Damaged Pole Mounted Equipment<br/>(Transformer, Recloser, Capacitor, Pole Top Switch)"
                  labelHTML={true}
                  error={formErrors.includes("damagedEquipmentCount")}
                  controlClass="w-11.25"
                  value={DAForm.damagedEquipmentCount}
                  required={true}
                  disabled={DAForm.okOnArrival}
                  setValue={(value) =>
                    updateDAForm("damagedEquipmentCount", value)
                  }
                />
              </FormRow>
              <FormRow>
                <RadioControl
                  label="Location of Damage"
                  name="damageLocation"
                  error={formErrors.includes("damageLocation")}
                  value={DAForm.damageLocation}
                  options={[EASEMENT_BACKYARD, SIDE_OF_ROAD]}
                  required={true}
                  disabled={DAForm.okOnArrival}
                  setValue={(value) =>
                    updateDAForm("damageLocation", value as DamageLocation)
                  }
                />
              </FormRow>
              <FormRow>
                <RadioControl
                  label="Truck Accessible"
                  name="truckAccessible"
                  error={formErrors.includes("truckAccessible")}
                  value={getYesNoRadioOptionValue(DAForm.truckAccessible)}
                  options={["Yes", "No"]}
                  required={true}
                  disabled={DAForm.okOnArrival}
                  setValue={(value) =>
                    updateDAForm("truckAccessible", value === "Yes")
                  }
                />
              </FormRow>
              <FormRow>
                <RadioControl
                  label="Tree Trim Required?"
                  name="treeTrim"
                  error={formErrors.includes("treeTrim")}
                  value={DAForm.treeTrim}
                  options={[NONE, LIGHT, HEAVY, EXTREME]}
                  required={true}
                  disabled={DAForm.okOnArrival}
                  setValue={(value) =>
                    updateDAForm("treeTrim", value as TreeTrim)
                  }
                />
              </FormRow>
              <FormRow className="mb-1-i">
                <RadioControl
                  label="Site Accessibility"
                  name="siteAccessibility"
                  error={formErrors.includes("siteAccessibility")}
                  value={DAForm.siteAccessibility}
                  options={[
                    STANDARD,
                    POOR_FIELD_CONDITIONS,
                    POOR_ROAD_CONDITIONS,
                    SWAMP,
                    ISLAND,
                  ]}
                  required={true}
                  disabled={DAForm.okOnArrival}
                  setValue={(value) =>
                    updateDAForm(
                      "siteAccessibility",
                      value as SiteAccessibility
                    )
                  }
                />
              </FormRow>
              <FormRow className="pl-1.5">
                <TextAreaControl
                  label="Accessibility Notes"
                  error={formErrors.includes("siteAccessibilityNotes")}
                  value={DAForm.siteAccessibilityNotes}
                  required={false}
                  disabled={DAForm.okOnArrival}
                  setValue={(value) =>
                    updateDAForm("siteAccessibilityNotes", value)
                  }
                />
              </FormRow>
              <FormRow>
                <div className="w-full flex flex-wrap justify-between items-center mb-1">
                  <p className="text-medium">Location of Broken Items</p>
                  <div className="flex flex-no-wrap items-center">
                    <p className="mr-0.75">Select Type:</p>
                    <div className="flex flex-no-wrap border border-dte-500 items-center">
                      <button
                        className={clsx(
                          "p-0.25",
                          brokenItemSelection === "Pole"
                            ? "bg-dte-500 text-white"
                            : "bg-white text-gray-400"
                        )}
                        onClick={() => setBrokenItemSelection("Pole")}
                      >
                        Pole
                      </button>
                      <button
                        className={clsx(
                          "p-0.25",
                          brokenItemSelection === "Cross Arm"
                            ? "bg-dte-500 text-white"
                            : "bg-white text-gray-500"
                        )}
                        onClick={() => setBrokenItemSelection("Cross Arm")}
                      >
                        Cross Arm
                      </button>
                    </div>
                  </div>
                </div>
                <DAMap
                  onMapClick={(coord) => {
                    if (brokenItemSelection === "Pole") {
                      setDAForm((prev) => ({
                        ...prev,
                        brokenPolesLocations: [
                          ...prev.brokenPolesLocations,
                          { location: coord, notes: "" },
                        ],
                      }));
                    } else {
                      setDAForm((prev) => ({
                        ...prev,
                        crossArmsLocations: [
                          ...prev.crossArmsLocations,
                          { location: coord, notes: "" },
                        ],
                      }));
                    }
                  }}
                  brokenCrossArms={DAForm.crossArmsLocations.map(
                    (x) => x.location
                  )}
                  brokenPoles={DAForm.brokenPolesLocations.map(
                    (x) => x.location
                  )}
                />
                <div className="flex flex-col w-full">
                  <p className="text-medium my-1">Poles</p>
                  <div className="ml-1.5">
                    {DAForm.brokenPolesLocations.length > 0 ? (
                      DAForm.brokenPolesLocations.map((bp, idx) => (
                        <BrokenItem
                          key={idx}
                          type="Pole"
                          brokenItem={bp}
                          index={idx}
                          removeItem={(index) =>
                            setDAForm((prev) => ({
                              ...prev,
                              brokenPolesLocations:
                                prev.brokenPolesLocations.filter(
                                  (x, i) => i !== index
                                ),
                            }))
                          }
                          changeType={switchBrokenItemType}
                          updateNote={(notes) =>
                            setDAForm((prev) => ({
                              ...prev,
                              brokenPolesLocations:
                                prev.brokenPolesLocations.map((x, i) => {
                                  if (i === idx) {
                                    return { ...x, notes: notes };
                                  } else {
                                    return x;
                                  }
                                }),
                            }))
                          }
                        />
                      ))
                    ) : (
                      <p className="text-gray-400 italic">
                        Select the type above then click or tap on the map to
                        add a pin.
                      </p>
                    )}
                  </div>
                </div>
                <div className="flex flex-col w-full">
                  <p className="text-medium my-1">Cross Arms</p>
                  <div className="ml-1.5">
                    {DAForm.crossArmsLocations.length > 0 ? (
                      DAForm.crossArmsLocations.map((ca, idx) => (
                        <BrokenItem
                          key={idx}
                          type="Cross Arm"
                          brokenItem={ca}
                          index={idx}
                          removeItem={(index) =>
                            setDAForm((prev) => ({
                              ...prev,
                              crossArmsLocations:
                                prev.crossArmsLocations.filter(
                                  (x, i) => i !== index
                                ),
                            }))
                          }
                          changeType={switchBrokenItemType}
                          updateNote={(notes) =>
                            setDAForm((prev) => ({
                              ...prev,
                              crossArmsLocations: prev.crossArmsLocations.map(
                                (x, i) => {
                                  if (i === idx) {
                                    return { ...x, notes: notes };
                                  } else {
                                    return x;
                                  }
                                }
                              ),
                            }))
                          }
                        />
                      ))
                    ) : (
                      <p className="text-gray-400 italic">
                        Select the type above then click or tap on the map to
                        add a pin.
                      </p>
                    )}
                  </div>
                </div>
              </FormRow>
              <FormRow>
                <PhotoUploadControl
                  label="Photos"
                  error={formErrors.includes("photos")}
                  values={DAForm.photos}
                  required={false}
                  disabled={DAForm.okOnArrival}
                  setValues={(values) => updateDAForm("photos", values)}
                />
              </FormRow>
              <FormRow>
                <TextAreaControl
                  label="Notes"
                  error={formErrors.includes("notes")}
                  value={DAForm.notes}
                  required={false}
                  disabled={DAForm.okOnArrival}
                  setValue={(value) => updateDAForm("notes", value)}
                />
              </FormRow>
            </div>
          </div>
          <div className="w-full absolute bottom-0 shadow-main py-1 px-0.5 xxs:px-2.5">
            <div className="w-full inline-flex flex-no-wrap justify-between items-center">
              <button
                className="px-1 py-0.5 bg-red-600 text-white border border-solid border-red-600"
                onClick={() => {
                  close();
                }}
              >
                Close
              </button>
              <div>
                <button
                  className="px-1 py-0.5 text-dte-500 border border-solid border-dte-500 box-border mr-1"
                  onClick={saveForm}
                >
                  Save
                </button>

                <button
                  className="px-1 py-0.5 bg-dte-500 text-white border border-solid border-dte-500"
                  onClick={() => {
                    submitForm(close);
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Popup>
  );
};

export default DamageAssessmentFormModal;

const FormRow: React.FC<{ className?: string }> = ({ children, className }) => (
  <div className={clsx("flex flex-wrap w-full mb-2", className)}>
    {children}
  </div>
);

interface IBrokenItem {
  type: BrokenPoleSelection;
  brokenItem: DamageAssessmentLocation;
  index: number;
  removeItem: (index: number) => void;
  changeType: (index: number, originalType: BrokenPoleSelection) => void;
  updateNote: (note: string) => void;
}

const BrokenItem: React.FC<IBrokenItem> = (props) => {
  return (
    <div className="flex flex-col mb-1">
      <div className="flex flex-no-wrap justify-between mb-0.25">
        <p className="text-medium">
          {props.type} {props.index + 1} Notes
        </p>
        <div className="flex flex-no-wrap">
          <button
            onClick={() => props.changeType(props.index, props.type)}
            className="mr-1 text-dte-500 underline"
          >
            Switch Type
          </button>
          <button
            onClick={() => props.removeItem(props.index)}
            className="text-red-600 underline"
          >
            Remove Pin
          </button>
        </div>
      </div>
      <textarea
        value={props.brokenItem.notes}
        className="p-0.5 h-5.5 bg-white w-full border border-dte-500"
        placeholder="Enter Notes..."
        onChange={(e) => props.updateNote(e.target.value)}
      />
    </div>
  );
};
