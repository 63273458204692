import React, { useState } from "react";
import Popup from "reactjs-popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import {
  CrewStatusUpdateReq,
  JobDetailShort,
  updateCrewStatus,
} from "../../lib/Api";
import { useAppDispatch, useAppSelector } from "../../lib/hooks";
import { setLoading } from "../../features/jobSlice";
import { trackLocationData } from "../../lib/api/trackLocationApi";

const AssignJobToCrewModal = (props: {
  job: JobDetailShort;
  crews: string[];
  afterSubmit: Function;
}) => {
  const [selectedCrew, setSelectedCrew] = useState(props.job.crewId);
  const dispatch = useAppDispatch();

  const role = useAppSelector((state) => state.role.value);

  const handleSubmit = async () => {
    dispatch(setLoading(true));
    //preempt first
    const pReq: CrewStatusUpdateReq = {
      msgId: props.job.jobId,
      jobId: props.job.jobId,
      action: "PR",
    };

    await trackLocationData("updateCrewStatus", props.job.crewId, role!);

    const pRes = await updateCrewStatus(props.job.crewId, pReq);
    if (pRes.tag === "ok") {
      //then assign
      const req: CrewStatusUpdateReq = {
        msgId: props.job.jobId,
        jobId: props.job.jobId,
        action: "AS",
      };

      if (selectedCrew !== "") {
        const res = await updateCrewStatus(selectedCrew, req);
        if (res.tag === "ok") {
          props.afterSubmit();
        } else {
          console.log("Failure at assign");
          const undoReq: CrewStatusUpdateReq = {
            msgId: props.job.jobId,
            jobId: props.job.jobId,
            action: "AS",
          };
          await updateCrewStatus(props.job.crewId, undoReq);
          alert(
            "Unable to reassign job, check your connection and make sure the crew is logged in. You can resend the request through the Drafts page"
          );

          props.afterSubmit();
        }
      }
    } else {
      console.log("Failure at Preempt");
      alert(
        "Unable to reassign job, check your connection and make sure the crew is logged in. You can resend the request through the Drafts page"
      );
      dispatch(setLoading(false));
    }
  };

  return (
    <>
      <Popup
        closeOnDocumentClick={true}
        modal={true}
        overlayStyle={{ cursor: "default" }}
        contentStyle={{ cursor: "default" }}
        className="responsive-modal"
        trigger={
          <div className="inline-flex flex-no-wrap whitespace-no-wrap justify-center items-center w-full cursor-pointer">
            {props.job.crewId}
            <FontAwesomeIcon className="ml-1" icon={faEdit} />
          </div>
        }
      >
        {(close) => (
          <div className="text-black py-1">
            <p className="mb-1">
              Select a logged in Crew to assign to this job
            </p>
            <div className="flex flex-wrap justify-center">
              {props.crews.length > 0 ? (
                props.crews.map((x) =>
                  x ? (
                    <button
                      className={`${
                        selectedCrew === x
                          ? "bg-white border border-dte-500 text-dte-500"
                          : "border border-dte-500 bg-dte-500 text-white"
                      } mx-0.5 my-0.5 py-0.5 px-0.75`}
                      onClick={() => setSelectedCrew(x)}
                      key={x}
                    >
                      {x}
                    </button>
                  ) : (
                    <></>
                  )
                )
              ) : (
                <p>No logged in Crews</p>
              )}
            </div>
            {props.crews.length > 0 ? (
              <div className="w-full inline-flex flex-no-wrap justify-center my-1">
                <button
                  className="px-1 py-0.5 bg-gray-200 font-bold mr-1"
                  onClick={() => {
                    handleSubmit();
                    setSelectedCrew(props.job.crewId);
                    close();
                  }}
                >
                  Submit
                </button>
                <button
                  className="px-1 py-0.5 bg-gray-200 font-bold ml-1"
                  onClick={() => {
                    setSelectedCrew(props.job.crewId);
                    close();
                  }}
                >
                  Cancel
                </button>
              </div>
            ) : (
              <div className="w-full inline-flex flex-no-wrap justify-center my-1">
                <button
                  className="px-1 py-0.5 bg-gray-200 font-bold ml-1"
                  onClick={() => {
                    setSelectedCrew(props.job.crewId);
                    close();
                  }}
                >
                  Close
                </button>
              </div>
            )}
          </div>
        )}
      </Popup>
    </>
  );
};

export default AssignJobToCrewModal;
