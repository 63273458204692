import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch } from "../ReduxStore";
import * as api from "../lib/Api";
import * as Util from "../lib/Utility";

const initialState = {
  value: Util.serviceCenters.filter((x) => x.value !== "All"),
};

export const getOutageCounts = createAsyncThunk<
  any,
  void,
  { dispatch: AppDispatch }
>("outageCounts/getOutageCounts", async () => {
  const res = await api.getJobDetailByFilter(
    Util.buildJobFilter("Outage", Util.ALL_SERVICE_CENTER)
  );
  if (res.tag === "ok" && (res.data as unknown) !== "") {
    const counts = Util.serviceCenters
      .filter((x) => x.value !== Util.ALL_SERVICE_CENTER)
      .map((x) => {
        const count = res.data
          .filter((y) => y.aor.slice(0, 3) === x.value)
          .map((x) => {
            if (x.customerOut && Number(x.customerOut) > 0) {
              return Number(x.customerOut);
            } else {
              return 1;
            }
          })
          .reduce((sum, x) => sum + x, 0);
        return { ...x, count };
      });
    return counts;
  } else {
    console.log(res);
  }
});

export const outageCountsSlice = createSlice({
  name: "outageCounts",
  initialState,
  reducers: {
    setOutageCounts: (state, action: PayloadAction<any>) => {
      state.value = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getOutageCounts.fulfilled, (state, action) => {
      // Add user to the state array
      state.value = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
export const { setOutageCounts } = outageCountsSlice.actions;

export default outageCountsSlice.reducer;
