import http from "../Http";
import { TrackLocationEvent } from "../models/TrackLocationEvent";
import { Role } from "../Utility";

export const trackLocationData = async (
  eventType: string,
  eventInfo: string,
  user: Role
) => {
  if (!navigator.geolocation) {
    return;
  }

  if (!user) {
    console.log("Location Data: No user found, refresh page");
    return;
  }

  let pos: GeolocationPosition = {
    coords: {
      accuracy: 0,
      altitude: 0,
      altitudeAccuracy: 0,
      heading: 0,
      latitude: 0,
      longitude: 0,
      speed: 0,
    },
    timestamp: new Date().getDate(),
  };

  try {
    pos = await getPosition();
  } catch (e) {
    console.error(e);
  }

  const trackLocationEvent: TrackLocationEvent = {
    eventType,
    eventInfo,
    crewIds: user.crews,
    eventDateTime: new Date().toISOString(),
    user: user.id,
    username: user.name,
    location: { lat: pos.coords.latitude, long: pos.coords.longitude },
  };

  const res = await http.post(
    `${process.env.REACT_APP_ADMIN_URL}/api/trackLocation`,
    trackLocationEvent,
    {
      params: {
        code: process.env.REACT_APP_ADMIN_API_KEY,
      },
    }
  );
  return res;
};

const getPosition = (
  options?: PositionOptions
): Promise<GeolocationPosition> => {
  return new Promise<GeolocationPosition>((resolve, reject) =>
    navigator.geolocation.getCurrentPosition(resolve, reject, options)
  );
};
