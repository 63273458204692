import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import { getTreeTrimActivities } from "../../features/treeTrimSlice";
import { JobDetail, JobDetailShort } from "../../lib/Api";
import { trackLocationData } from "../../lib/api/trackLocationApi";
import { saveTreeTrimActivities } from "../../lib/api/treeTrimApi";
import { useAppDispatch, useAppSelector } from "../../lib/hooks";
import {
  initTreeTrimActivity,
  TreeTrimActivity,
  TreeTrimActivityKey,
  validateTreeTrimActivity,
} from "../../lib/models/TreeTrimActivity";
import TreeTrimActivityForm from "./TreeTrimActivityForm";

const TreeTrimActivitiesModal: React.FC<{
  job: JobDetail | JobDetailShort;
}> = ({ job }) => {
  const dispatch = useAppDispatch();
  const role = useAppSelector((state) => state.role.value);
  const existingActivities = useAppSelector(
    (state) => state.treeTrim.activities
  );
  const [formActivities, setFormActivities] =
    useState<TreeTrimActivity[]>(existingActivities);
  const [removedTreeTrimIds, setRemovedTreeTrimIds] = useState<string[]>([]);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<
    TreeTrimActivityKey[][]
  >([]);

  const addActivity = () => {
    const activity = initTreeTrimActivity();
    activity.jobId = job.jobId;
    activity.jobType = job.jobType;
    activity.jobSubType = job.jobSubType;
    activity.circuit = job.circuit;
    activity.jobCreateDate = job.outageDate;
    activity.createDate = new Date().toISOString();
    activity.createUser = role?.id || "";

    const activities = [...formActivities, activity];
    setFormActivities(activities);
  };

  const removeActivity = (index: number) => {
    const activities = [...formActivities];
    const [removedActivity] = activities.splice(index, 1);
    setFormActivities(activities);
    if (removedActivity.id) {
      setRemovedTreeTrimIds((prev) => [...prev, removedActivity.id]);
    }
  };

  const updateTreeTrimActivity = (
    treeTrimActivity: TreeTrimActivity,
    index: number
  ) => {
    const existingItems = [...formActivities];

    setFormActivities([
      ...existingItems.slice(0, index),
      treeTrimActivity,
      ...existingItems.slice(index + 1),
    ]);
  };

  const isError = (errorFields: TreeTrimActivityKey[][]): boolean => {
    //if none of the forms have errors there will be no arrays of errors; index will be -1
    return !(errorFields.findIndex((ve) => ve.length > 0) === -1);
  };

  const disableSubmit = (): boolean => {
    return (
      isSubmitting || formActivities.length === 0 || isError(validationErrors)
    );
  };

  const validate = (): TreeTrimActivityKey[][] => {
    setValidationErrors([]);
    const errors: TreeTrimActivityKey[][] = [];
    for (let i = 0; i < formActivities.length; i++) {
      errors.push([]);
      const activityErrors = validateTreeTrimActivity(formActivities[i]);
      errors[i] = activityErrors;
    }
    setValidationErrors(errors);
    return errors;
  };

  const submit = async (close: () => void) => {
    setIsDirty(true);
    setIsSubmitting(true);

    const preSubmitErrors = validate();

    if (isError(preSubmitErrors)) {
      setIsSubmitting(false);
      return;
    }

    await trackLocationData("saveTreeTrimActivity", job.jobId, role!);

    const results = await saveTreeTrimActivities(
      formActivities,
      removedTreeTrimIds
    );

    const apiErrors = results
      .map((r) => {
        if (r?.tag === "err") {
          return r.err.response.data.message;
        }
        return "";
      })
      .filter((err) => err !== "");

    if (apiErrors.length) {
      apiErrors.forEach((err) => console.error(err));
      alert("Error saving tree trim activities");
    } else {
      dispatch(getTreeTrimActivities(job.jobId));
      close();
      setIsDirty(false);
    }

    setIsSubmitting(false);

    return;
  };

  const cancelForm = async () => {
    setFormActivities(existingActivities);
    setValidationErrors([]);
    setIsSubmitting(false);
    setIsDirty(false);
  };

  useEffect(() => {
    setFormActivities(existingActivities);
  }, [existingActivities]);

  useEffect(() => {
    if (isDirty) {
      setValidationErrors(validate());
    }
  }, [formActivities]);

  return (
    <Popup
      closeOnDocumentClick={false}
      modal={true}
      overlayStyle={{ cursor: "default" }}
      contentStyle={{ cursor: "default" }}
      className="responsive-modal"
      onOpen={() => {
        if (!existingActivities.length) {
          addActivity();
        }
      }}
      trigger={
        <button className="mr-0.5 mb-0.25 bg-dte-500 py-0.25 px-0.5 text-white">
          Add Activity
        </button>
      }
    >
      {(close) => (
        <div className="relative flex flex-col min-h-modal">
          <div className="flex flex-wrap justify-center px-2 mb-5 overflow-auto">
            <h1 className="w-full text-2xl p-2.5 text-center">
              Tree Trim Activities
            </h1>
            <div className="w-3/4">
              {formActivities.map((tt, index) => (
                <React.Fragment key={index}>
                  <div className="text-right mb-1">
                    <button
                      onClick={() => {
                        removeActivity(index);
                      }}
                      className="text-red-600 underline"
                    >
                      Remove Activity
                    </button>
                  </div>
                  <TreeTrimActivityForm
                    treeTrimActivity={tt}
                    onUpdate={(value) => {
                      updateTreeTrimActivity(value, index);
                    }}
                    errors={validationErrors[index]}
                  />
                </React.Fragment>
              ))}
              <div className="w-full mb-1">
                <button
                  onClick={() => addActivity()}
                  className="border border-solid border-dte-600 py-0.5 px-1 text-dte-600 w-full"
                >
                  + Add Activity
                </button>
              </div>
              <div className="flex justify-center">
                <button
                  disabled={disableSubmit()}
                  onClick={async () => {
                    await submit(close);
                  }}
                  className="bg-dte-500 border border-solid border-gray-600 py-0.5 px-1 text-white mx-0.5
                                    disabled:bg-gray-300 disabled:text-gray-500 disabled:border-gray-200"
                >
                  Submit
                </button>
                <button
                  className="px-1 py-0.5 bg-gray-600 text-white border border-solid border-gray-600 mx-0.5"
                  onClick={() => {
                    cancelForm();
                    close();
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Popup>
  );
};

export default TreeTrimActivitiesModal;
