import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useHistory } from "react-router-dom";
import * as Api from "../lib/Api";
import * as Util from "../lib/Utility";
import CrewAssignStatusModal from "../components/modals/CrewAssignStatusModal";
import AssignJobToCrewModal from "../components/modals/AssignJobToCrewModal";
import { getJobsByCrews } from "../features/jobSlice";
import { useAppSelector, useAppDispatch } from "../lib/hooks";

type SortKey = keyof Api.JobDetailShort;

type SortDir = "asc" | "desc";

const CrewsPage = (): React.ReactElement => {
  const dispatch = useAppDispatch();
  const role = useAppSelector((state) => state.role.value);
  const crewJobs = useAppSelector((state) => state.jobs.crewJobs);
  const [crewStatuses, setCrewStatuses] = React.useState<
    Api.CrewStatusByCrewList[]
  >([]);
  const [sortKey, setSortKey] = React.useState<SortKey | null>(null);
  const [sortDir, setSortDir] = React.useState<SortDir>("desc");

  const [crewFilter, setCrewFilter] = React.useState("ALL");

  const [crewStatusLoading, setCrewStatusLoading] = React.useState(false);

  React.useEffect(() => {
    dispatch(getJobsByCrews());

    setCrewStatusLoading(true);
    Api.getCrewStatusByCrewList(role?.crews ?? [])
      .then((res) => {
        if (res.tag === "ok") {
          setCrewStatuses(res.data);
        } else {
          alert("Error fetching crew statuses");
        }
      })
      .finally(() => setCrewStatusLoading(false));
  }, []);

  const setSort = (key: SortKey) => {
    if (key === sortKey) {
      if (sortDir === "desc") {
        setSortDir("asc");
      } else if (sortDir === "asc") {
        setSortDir("desc");
        setSortKey(null);
      }
    } else {
      setSortKey(key);
    }
  };

  const toggleCrewFilter = (crew: string) => {
    if (crew === crewFilter) {
      setCrewFilter("ALL");
    } else {
      setCrewFilter(crew);
    }
  };

  const loggedInCrews = crewStatuses
    .filter((x) => "LogonStatus" in x && x.LogonStatus === "Y")
    .map((x) => x.crewId);

  return (
    <div className="flex flex-wrap text-black mx-1">
      <div className="w-full h-full md:w-1/2 md:mt-0.5 flex flex-wrap mb-1 justify-center md:justify-start">
        {role?.crews.map((crew, i) => {
          const jobsAssigned = crewJobs.filter((x) => x.crewId === crew).length;
          const crewStatus = crewStatuses.find((x) => x.crewId === crew);
          if (crewStatusLoading) {
            return (
              <CrewCard
                key={i}
                crewId={crew}
                crewStatus={"Fetching..."}
                jobsAssigned={jobsAssigned}
                logonStatus={"Loading"}
                onClick={() => toggleCrewFilter(crew)}
                selected={crew === crewFilter}
              />
            );
          }
          if (!crewStatus || "errorCode" in crewStatus) {
            return (
              <CrewCard
                key={i}
                crewId={crew}
                crewStatus={"Error"}
                jobsAssigned={jobsAssigned}
                logonStatus={"Err"}
                onClick={() => toggleCrewFilter(crew)}
                selected={crew === crewFilter}
              />
            );
          } else {
            const crewStatusLabel = Util.crewStatusValueToLabel(
              crewStatus.crewAssignmentStatus
            );
            return (
              <CrewCard
                key={i}
                crewId={crew}
                crewStatus={crewStatusLabel}
                jobsAssigned={jobsAssigned}
                logonStatus={crewStatus.LogonStatus}
                onClick={() => toggleCrewFilter(crew)}
                selected={crew === crewFilter}
              />
            );
          }
        })}
      </div>
      <div className="w-full md:w-1/2 md:mt-1 flex flex-wrap max-h-screen h-full overflow-x-auto">
        <table className="table-auto bg-white w-full text-black border mb-2">
          <thead className="bg-gray-200">
            <tr className="cursor-pointer">
              <TableHeader
                value="crewId"
                label="Crew"
                onClick={setSort}
                sortKey={sortKey}
                sortDir={sortDir}
              />
              <TableHeader
                value="crewAssignmentStatus"
                label="Crew Assign. Status"
                onClick={setSort}
                sortKey={sortKey}
                sortDir={sortDir}
              />
              <TableHeader
                value="jobId"
                label="Job"
                onClick={setSort}
                sortKey={sortKey}
                sortDir={sortDir}
              />
              <TableHeader
                value="jobType"
                label="Type"
                onClick={setSort}
                sortKey={sortKey}
                sortDir={sortDir}
              />
            </tr>
          </thead>
          <tbody>
            {role
              ? sortByHeader(sortKey, sortDir, crewJobs)
                  .filter((x) =>
                    crewFilter === "ALL" ? x : x.crewId === crewFilter
                  )
                  .map((job, i) => (
                    <TableRow
                      job={job}
                      key={i}
                      _key={job.jobId}
                      crews={loggedInCrews}
                      afterSubmit={() => dispatch(getJobsByCrews())}
                    />
                  ))
              : null}
          </tbody>
        </table>
      </div>
    </div>
  );
};

type LogonStatus = "Y" | "N" | "Err" | "Loading";
type LogonStatusProps = {
  status: LogonStatus;
};

const LogonStatusUI = (props: LogonStatusProps) => {
  if (props.status === "Err") {
    return (
      <div className="inline-flex flex-no-wrap items-center">
        <div className="w-0.5 h-0.5 rounded-ful bg-red-400 mr-0.5" />
        <p className="font-bold">Error fetching status</p>
      </div>
    );
  } else if (props.status === "N") {
    return (
      <div className="inline-flex flex-no-wrap items-center">
        <div className="w-0.5 h-0.5 rounded-full bg-gray-500 mr-0.5" />
        <p className="font-bold">Logged Out</p>
      </div>
    );
  } else if (props.status === "Loading") {
    return (
      <div className="inline-flex flex-no-wrap items-center">
        <div className="w-0.5 h-0.5 rounded-full bg-yellow-500 mr-0.5" />
        <p className="font-bold">Loading...</p>
      </div>
    );
  } else {
    return (
      <div className="inline-flex flex-no-wrap items-center">
        <div className="w-0.5 h-0.5 rounded-full bg-green-600 mr-0.5" />
        <p className="font-bold">Logged In</p>
      </div>
    );
  }
};

type CrewCardProps = {
  crewId: string;
  crewStatus: string;
  jobsAssigned: number;
  logonStatus: LogonStatus;
  onClick: Function;
  selected: boolean;
};

const CrewCard = (props: CrewCardProps) => (
  <div
    className={`w-16 h-8 m-0.5 rounded p-1 shadow-md cursor-pointer ${
      props.selected ? "bg-gray-200" : "bg-white"
    }`}
    onClick={() => props.onClick()}
  >
    <p>Crew: {props.crewId}</p>
    <p>Crew Status: {props.crewStatus}</p>
    <p>Jobs Assigned: {props.jobsAssigned}</p>
    <LogonStatusUI status={props.logonStatus} />
  </div>
);

const TableHeader = (props: {
  value: SortKey;
  label: String;
  onClick: (key: SortKey) => void;
  sortKey: SortKey | null;
  sortDir: SortDir;
}) => (
  <th
    scope="col"
    className="px-0.25 md:px-1 py-0.5 whitespace-no-wrap"
    onClick={() => props.onClick(props.value)}
  >
    {props.label}

    <FontAwesomeIcon
      className={`ml-0.5 ${
        props.value === props.sortKey ? "opacity-100" : "opacity-0"
      } `}
      icon={props.sortDir === "asc" ? faCaretUp : faCaretDown}
    />
  </th>
);

const TableRow: React.FC<{
  job: Api.JobDetailShort;
  crews: string[];
  _key: number | string;
  afterSubmit: () => void;
}> = ({ job, crews, _key, afterSubmit }) => {
  const history = useHistory();

  return (
    <tr className="hover:bg-gray-200">
      <td
        className="border text-center py-0.5"
        onClick={(e) => e.stopPropagation()}
      >
        <AssignJobToCrewModal
          job={job}
          crews={crews}
          afterSubmit={() => setTimeout(afterSubmit, 1500)}
        />
      </td>
      <td className="border text-center py-0.5">
        <CrewAssignStatusModal
          job={job}
          afterSubmit={afterSubmit}
          crewAssignmentStatus={job.crewAssignmentStatus}
          crewId={job.crewId}
        />
      </td>
      <td
        className="border px-0.25 md:px-1 text-center font-bold py-0.5 hover:bg-gray-400 cursor-pointer"
        onClick={() => history.push("/details/" + _key)}
      >
        {job.jobId}
      </td>
      <td className="border px-0.25 md:px-1 text-center py-0.5">
        {job.jobType}
      </td>
    </tr>
  );
};

const sortByHeader = (
  key: SortKey | null,
  dir: SortDir,
  data: Api.JobDetailShort[]
) => {
  if (key !== null) {
    switch (dir) {
      case "asc":
        const ascList = [...data].sort((a, b) => (a[key]! < b[key]! ? 1 : -1));
        return ascList;
      case "desc":
        const descList = [...data].sort((a, b) => (a[key]! > b[key]! ? 1 : -1));
        return descList;
    }
  } else {
    return data;
  }
};

export default CrewsPage;
