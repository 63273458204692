import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import { uploadImage } from "../../lib/api/damageAssessmentApi";

export const PhotoUploadControl: React.FC<{
  label: string;
  values: string[];
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  setValues: (value: string[]) => void;
}> = ({ label, values, setValues, required, disabled, error }) => {
  const onPhotoChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const res = await uploadImage(e.target.files[0]);
      if (res.tag === "ok") {
        setValues([...values, res.data.message]);
      } else {
        console.log(res.err);
        alert("Error uploading image");
      }
    }
  };

  const removePhoto = (index: number) => {
    setValues(values.filter((y, i) => i !== index));
  };

  return (
    <div className={clsx("flex flex-col w-full", disabled && "disabled")}>
      <div className="flex flex-no-wrap mb-0.5 w-full">
        <label
          className={clsx("font-medium mr-0.25", error && "error-control")}
        >
          {label}
        </label>
        {required && <p className="text-red-600">*</p>}
      </div>
      <div className="flex flex-wrap w-full">
        <input
          accept=".png,.jpg,.jpeg"
          onChange={onPhotoChange}
          type="file"
          className="text-opacity-0"
        />
        {values.length > 0 && (
          <div className="border border-gray-300 w-full mt-0.5 flex flex-wrap">
            {values.map((x, i) => (
              <div key={i} className="relative">
                <img alt="form image" src={x} className="w-10 h-10 m-0.5" />
                <button
                  onClick={() => removePhoto(i)}
                  className="absolute top-0 right-0"
                >
                  <FontAwesomeIcon
                    className="text-red-600 rounded-full mt-0.25 mr-0.25 bg-white border border-white"
                    size="lg"
                    icon={faTimesCircle}
                  />
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
