import { faSpinner, faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Popup from "reactjs-popup";
import * as api from "../../lib/Api";
import { setLoading } from "../../features/jobSlice";
import { useAppSelector, useAppDispatch } from "../../lib/hooks";
import { trackLocationData } from "../../lib/api/trackLocationApi";

const TakePhotoModal = (props: { jobId: string; afterSubmit: Function }) => {
  const [submitting, setSubmitting] = React.useState(false);
  const [image, setImage] = React.useState<File | null>(null);
  const [open, setOpen] = React.useState(false);
  const [mode, setMode] = React.useState<"user" | "environment">("environment");
  const [iosChromeErr, setiOSChromeErr] = React.useState(false);
  const dispatch = useAppDispatch();
  const role = useAppSelector((state) => state.role.value);

  const video = React.useRef<HTMLVideoElement>(null);
  const canvas = React.useRef<HTMLCanvasElement>(null);

  const currentStream = React.useRef<MediaStream | null>(null);

  const cleanup = () => {
    currentStream.current?.getTracks().forEach((x) => x.stop());
  };

  React.useEffect(() => {
    if (!navigator?.mediaDevices?.getUserMedia) {
      setiOSChromeErr(true);
    } else {
      if (open) {
        if (currentStream.current) {
          cleanup();
        }
        navigator.mediaDevices
          ?.getUserMedia({
            video: { facingMode: mode },
            audio: false,
          })
          .then((stream) => {
            currentStream.current = stream;
            if (video.current) {
              video.current.srcObject = stream;
              video.current.onloadedmetadata = () => {
                video.current?.play();
              };
            }
          })
          .catch((e) => {
            alert(
              "Error opening camera, try allowing access to the camera for this site and try again"
            );
          });
      }
    }
  }, [mode, open]);

  const handleSubmit = async () => {
    setSubmitting(true);
    if (image) {
      const res = await api.uploadImage(image);
      if (res.tag === "ok") {
        await trackLocationData("updateJob", props.jobId, role!);

        const updateRes = await api.updateJob(props.jobId, {
          msgId: props.jobId,
          photo: res.data.message,
          jobComments: `${role?.name ?? ""} uploaded photo: ${
            res.data.message
          }`,
        });
        if (updateRes.tag === "ok") {
          props.afterSubmit();
        }
      } else {
        alert("Error uploading image, check your internet connection.");
        dispatch(setLoading(false));
      }
    }
    setSubmitting(false);
  };

  const closeWrapper = (close: () => void) => {
    setImage(null);
    cleanup();
    close();
  };

  const takePhoto = () => {
    if (canvas.current && video.current) {
      canvas.current.width = video.current?.videoWidth;
      canvas.current.height = video.current?.videoHeight;
      const ctx = canvas.current.getContext("2d");
      ctx?.drawImage(video.current, 0, 0);
      canvas.current.toBlob((blob) => {
        if (blob) {
          const img = new File([blob], `${props.jobId}-${Date.now()}.png`, {
            type: "image/png",
          });
          setImage(img);
        }
      });
    }
  };

  React.useEffect(() => {
    return () => cleanup();
  }, []);

  return (
    <>
      <button
        onClick={() => setOpen(!open)}
        className="mr-0.5 mb-0.25 bg-dte-500 py-0.25 px-0.5 text-white"
      >
        Take Photo
      </button>
      <Popup
        closeOnDocumentClick={true}
        modal={true}
        overlayStyle={{ cursor: "default" }}
        contentStyle={{
          cursor: "default",
          width: "99%",
          maxHeight: "95%",
          overflow: "auto",
        }}
        className="responsive-modal"
        open={open}
        onClose={() => {
          setOpen(false);
          setImage(null);
          cleanup();
        }}
      >
        {(close) => (
          <div className="text-black py-1">
            {iosChromeErr ? (
              <h1 className="w-20">
                This browser does not support taking photos inside the browser,
                if you are using an Apple device try again using Safari.
              </h1>
            ) : (
              <>
                {" "}
                <p className="mb-1 text-center">Take Photo</p>
                <video
                  muted={true}
                  autoPlay={true}
                  playsInline={true}
                  controls={false}
                  className="m-auto w-full"
                  ref={video}
                  style={{
                    display: image ? "none" : "inherit",
                    maxHeight: "75vh",
                  }}
                />
                <canvas
                  className="m-auto"
                  ref={canvas}
                  style={{
                    display: !image ? "none" : "inherit",
                    maxHeight: "75vh",
                  }}
                />
                <div className="w-full inline-flex flex-no-wrap justify-center items-center my-1">
                  {submitting ? (
                    <>
                      <FontAwesomeIcon
                        className="mt-0.25"
                        icon={faSpinner}
                        spin
                      />
                      <p className="ml-0.25">Submitting...</p>
                    </>
                  ) : image ? (
                    <>
                      <button
                        className="px-1 py-0.5 bg-gray-200 font-bold mr-1"
                        onClick={() =>
                          handleSubmit().then(() => closeWrapper(close))
                        }
                      >
                        Submit
                      </button>
                      <button
                        className="px-1 py-0.5 bg-gray-200 font-bold ml-1"
                        onClick={() => closeWrapper(close)}
                      >
                        Cancel
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="px-1 py-0.5 bg-gray-200 font-bold ml-1"
                        onClick={takePhoto}
                      >
                        Take Photo
                      </button>
                      <button
                        className="px-1 py-0.5 bg-gray-200 font-bold ml-1"
                        onClick={() =>
                          setMode(
                            mode === "environment" ? "user" : "environment"
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faSync} />
                      </button>
                      <button
                        className="px-1 py-0.5 bg-gray-200 font-bold ml-1"
                        onClick={() => closeWrapper(close)}
                      >
                        Cancel
                      </button>
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        )}
      </Popup>
    </>
  );
};

export default TakePhotoModal;
