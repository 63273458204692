import React from "react";
import Popup from "reactjs-popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import * as Api from "../../lib/Api";
import * as Util from "../../lib/Utility";
import { useAppSelector, useAppDispatch } from "../../lib/hooks";
import { setLoading } from "../../features/jobSlice";
import { trackLocationData } from "../../lib/api/trackLocationApi";

const ACKNOWLEDGED = "AK";
const EN_ROUTE = "ER";
const ARRIVED = "AR";
const PREEMPT = "PR";
const DISPATCHED = "DP";
const ASSIGN = "AS";

type LoadingCrewStatus = {
  tag: "LoadingCrewStatus";
};

type ErrCrewStatus = {
  tag: "ErrCrewStatus";
};

type DPCrewStatus = {
  tag: "DPCrewStatus";
  crewStatus: string;
  jobId: string;
};

type AVCrewStatus = {
  tag: "AVCrewStatus";
  crewStatus: string;
};

type ModalCrewStatus =
  | LoadingCrewStatus
  | ErrCrewStatus
  | DPCrewStatus
  | AVCrewStatus;

const buildCrewAssignmentReq = (
  jobId: string,
  name: string,
  crewAssignStatus: string
): Api.CrewAssignmentStatusUpdateReq => ({
  msgId: jobId,
  jobId: jobId,
  comments: `OS_${name} updated Crew Assignment Status to ${crewAssignStatus}`,
  crewAssignmentStatus: crewAssignStatus,
});

const CrewAssignStatusModal = (props: {
  job: Api.JobDetail | Api.JobDetailShort | Api.JobByCrew;
  afterSubmit: Function;
  crewAssignmentStatus: string;
  crewId: string;
  disabled?: boolean;
}) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [crewStatus, setCrewStatus] = React.useState<ModalCrewStatus>({
    tag: "LoadingCrewStatus",
  });
  const [selectedCrewAssignStatus, setSelectedCrewAssignStatus] =
    React.useState<string | null>(
      props.crewAssignmentStatus || props.job.crewAssignmentStatus || null
    );
  const role = useAppSelector((state) => state.role.value);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (modalOpen) {
      Api.getCrewStatusByCrewList([props.crewId])
        .then((res) => {
          if (
            res.tag === "ok" &&
            res.data[0] &&
            !("errorCode" in res.data[0])
          ) {
            const cs = res.data[0];
            if (!Util.availableCrewStatuses.includes(cs.crewAssignmentStatus)) {
              setCrewStatus({
                tag: "DPCrewStatus",
                crewStatus: cs.crewAssignmentStatus,
                jobId: cs.jobId,
              });
            } else {
              setCrewStatus({
                tag: "AVCrewStatus",
                crewStatus: cs.crewAssignmentStatus,
              });
            }
          } else {
            setCrewStatus({ tag: "ErrCrewStatus" });
          }
        })
        .catch((err) => {
          console.log(err);
          setCrewStatus({ tag: "ErrCrewStatus" });
        });
    }
  }, [modalOpen]);

  React.useEffect(() => {
    setSelectedCrewAssignStatus(null);
  }, [props.job]);

  const addComment = async (comment: string) => {
    await trackLocationData("updateJob", props.job.jobId, role!);
    await Api.updateJob(props.job.jobId, {
      msgId: props.job.jobId,
      jobComments: comment,
    });
  };

  const handleSubmit = async () => {
    if (
      ((props.crewId !== "" && props.crewId !== null) ||
        (props.job.crewId !== "" && props.job.crewId !== null)) &&
      selectedCrewAssignStatus
    ) {
      dispatch(setLoading(true));

      const crewId = props.crewId || props.job.crewId;
      const name = role?.name ?? "";
      const jobId = props.job.jobId;

      await trackLocationData("updateCrewAssignmentStatus", crewId, role!);

      //If Acknowledged is selected, update crew assignment status normally regardless of status
      if (selectedCrewAssignStatus === ACKNOWLEDGED) {
        const req = buildCrewAssignmentReq(
          jobId,
          name,
          selectedCrewAssignStatus
        );

        const res = await Api.updateCrewAssignmentStatus(crewId, req);
        if (res.tag === "ok") {
          await addComment(req.comments);
          props.afterSubmit();
        } else {
          alert(
            "Crew assignment update failed, check your internet connection. You can resend the request through the Drafts page"
          );
        }
      } else {
        //If Crew is currently Dispatched
        if (crewStatus.tag === "DPCrewStatus") {
          //If this job is the job that the crew is currently dispatched to
          //allow updating the crew assignment status to any value
          if (props.job.jobId === crewStatus.jobId) {
            const req = buildCrewAssignmentReq(
              jobId,
              name,
              selectedCrewAssignStatus
            );
            const res = await Api.updateCrewAssignmentStatus(crewId, req);
            if (res.tag === "ok") {
              await addComment(req.comments);
              props.afterSubmit();
            } else {
              alert(
                "Crew assignment update failed, check your internet connection. You can resend the request through the Drafts page"
              );
            }
          } else {
            //If crew is dispatched to another job
            // If Enroute is selected, preempt the crew off their current job, dispatch assign that current job back onto them, then dispatch them to the selected job,
            // then update crew assignment status
            //Preempt first
            const pReq: Api.CrewStatusUpdateReq = {
              msgId: crewStatus.jobId,
              jobId: crewStatus.jobId,
              action: PREEMPT,
            };
            const pRes = await Api.updateCrewStatus(crewId, pReq);
            if (pRes.tag === "ok") {
              //Dispatch assign old job
              const daReq: Api.CrewStatusUpdateReq = {
                msgId: crewStatus.jobId,
                jobId: crewStatus.jobId,
                action: ASSIGN,
              };
              const daRes = await Api.updateCrewStatus(crewId, daReq);
              if (daRes.tag === "ok") {
                //Then dispatch
                const dReq: Api.CrewStatusUpdateReq = {
                  msgId: jobId,
                  jobId: jobId,
                  action: DISPATCHED,
                };
                const dRes = await Api.updateCrewStatus(crewId, dReq);
                if (dRes.tag === "ok") {
                  //Then update to crew assignment status
                  const req = buildCrewAssignmentReq(
                    jobId,
                    name,
                    selectedCrewAssignStatus
                  );
                  const res = await Api.updateCrewAssignmentStatus(crewId, req);
                  if (res.tag === "ok") {
                    await addComment(req.comments);
                    props.afterSubmit();
                  } else {
                    alert(
                      "Crew assignment update failed, check your internet connection. You can resend the request through the Drafts page"
                    );
                  }
                } else {
                  alert(
                    "Crew assignment update failed, check your internet connection. You can resend the request through the Drafts page"
                  );
                }
              }
            } else {
              alert(
                "Crew assignment update failed, check your internet connection. You can resend the request through the Drafts page"
              );
            }
          }
        } else {
          // If crew is not currently dispatched, dispatch crew to selected job, then update crew assignment status
          //First Dispatch
          const dReq: Api.CrewStatusUpdateReq = {
            msgId: jobId,
            jobId: jobId,
            action: DISPATCHED,
          };
          const dRes = await Api.updateCrewStatus(crewId, dReq);
          if (dRes.tag === "ok") {
            //Then update to crew assignment status
            const req = buildCrewAssignmentReq(
              jobId,
              name,
              selectedCrewAssignStatus
            );
            const res = await Api.updateCrewAssignmentStatus(crewId, req);
            if (res.tag === "ok") {
              await addComment(req.comments);
              props.afterSubmit();
            } else {
              alert(
                "Crew assignment update failed, check your internet connection. You can resend the request through the Drafts page"
              );
            }
          } else {
            alert(
              "Crew assignment update failed, check your internet connection. You can resend the request through the Drafts page"
            );
          }
        }
      }
    }
    dispatch(setLoading(false));
  };

  const label =
    props.job.crewAssignmentStatus === ""
      ? "N/A"
      : Util.crewStatusValueToLabel(
          props.crewAssignmentStatus || props.job.crewAssignmentStatus
        );

  return (
    <>
      <Popup
        closeOnDocumentClick={true}
        modal={true}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onOpen={() => setModalOpen(true)}
        overlayStyle={{ cursor: "default" }}
        contentStyle={{ cursor: "default" }}
        disabled={props.disabled || props.job.crewId === ""}
        className="responsive-modal"
        trigger={
          <button
            className={`inline-flex flex-no-wrap whitespace-no-wrap items-center justify-center w-full ${
              !props.disabled && "cursor-pointer"
            } ${label === "N/A" ? "text-red-600" : "text-green-600"}`}
          >
            {label}
            {!props.disabled && props.crewId !== "" && (
              <FontAwesomeIcon className="ml-1" icon={faEdit} />
            )}
          </button>
        }
      >
        {(close) => {
          const options = () => {
            if (crewStatus.tag === "LoadingCrewStatus") {
              return <p>Loading...</p>;
            } else if (crewStatus.tag === "DPCrewStatus") {
              if (crewStatus.jobId === props.job.jobId) {
                return (
                  <>
                    <CrewAssignmentButton
                      status={ACKNOWLEDGED}
                      selectedStatus={selectedCrewAssignStatus ?? ""}
                      setSelectedStatus={setSelectedCrewAssignStatus}
                    />
                    <CrewAssignmentButton
                      status={EN_ROUTE}
                      selectedStatus={selectedCrewAssignStatus ?? ""}
                      setSelectedStatus={setSelectedCrewAssignStatus}
                    />
                    <CrewAssignmentButton
                      status={ARRIVED}
                      selectedStatus={selectedCrewAssignStatus ?? ""}
                      setSelectedStatus={setSelectedCrewAssignStatus}
                    />
                  </>
                );
              } else {
                return (
                  <>
                    <CrewAssignmentButton
                      status={ACKNOWLEDGED}
                      selectedStatus={selectedCrewAssignStatus ?? ""}
                      setSelectedStatus={setSelectedCrewAssignStatus}
                    />
                    <CrewAssignmentButton
                      status={EN_ROUTE}
                      selectedStatus={selectedCrewAssignStatus ?? ""}
                      setSelectedStatus={setSelectedCrewAssignStatus}
                    />
                  </>
                );
              }
            } else if (crewStatus.tag === "AVCrewStatus") {
              return (
                <>
                  <CrewAssignmentButton
                    status={ACKNOWLEDGED}
                    selectedStatus={selectedCrewAssignStatus ?? ""}
                    setSelectedStatus={setSelectedCrewAssignStatus}
                  />
                  <CrewAssignmentButton
                    status={EN_ROUTE}
                    selectedStatus={selectedCrewAssignStatus ?? ""}
                    setSelectedStatus={setSelectedCrewAssignStatus}
                  />
                </>
              );
            } else {
              return <p>Error getting current Crew Status</p>;
            }
          };

          return (
            <div className="text-black py-1">
              <p className="mb-1">Select the Crew Status</p>
              <div className="flex flex-col">{options()}</div>
              {(crewStatus.tag === "AVCrewStatus" ||
                crewStatus.tag === "DPCrewStatus") && (
                <div className="w-full inline-flex flex-no-wrap justify-center items-center my-1">
                  {(props.crewAssignmentStatus !== "AR" ||
                    props.job.crewAssignmentStatus !== "AR") && (
                    <button
                      className="px-1 py-0.5 bg-gray-200 font-bold mr-1"
                      onClick={() => {
                        handleSubmit();
                        close();
                      }}
                      disabled={!selectedCrewAssignStatus}
                    >
                      Submit
                    </button>
                  )}

                  <button
                    className="px-1 py-0.5 bg-gray-200 font-bold ml-1"
                    onClick={() => {
                      setSelectedCrewAssignStatus(null);
                      close();
                    }}
                  >
                    Cancel
                  </button>
                </div>
              )}
            </div>
          );
        }}
      </Popup>
    </>
  );
};

export default CrewAssignStatusModal;

const CrewAssignmentButton = (props: {
  status: string;
  selectedStatus: string;
  setSelectedStatus: Function;
}) => (
  <button
    className={`${
      props.selectedStatus === props.status
        ? "bg-white border border-dte-500 text-dte-500"
        : "border border-dte-500 bg-dte-500 text-white"
    } mx-0.5 my-0.5 py-0.5 px-0.75`}
    onClick={() => props.setSelectedStatus(props.status)}
    key={props.status}
  >
    {props.status}
  </button>
);
