import { AxiosRequestConfig } from "axios";
import http from "../Http";

export const EASEMENT_BACKYARD = "Easement/Backyard";
export const SIDE_OF_ROAD = "Side of Road ";
export type DamageLocation = typeof EASEMENT_BACKYARD | typeof SIDE_OF_ROAD;

export const SUB_TRANSMISSION = "Sub-Transmission";
export const DISTRIBUTION = "Distribution";
export const SECONDARY = "Secondary";
export type Voltage =
  | typeof SUB_TRANSMISSION
  | typeof DISTRIBUTION
  | typeof SECONDARY;

export const NONE = "None";
export const LIGHT = "Light";
export const HEAVY = "Heavy";
export const EXTREME = "Extreme";
export type TreeTrim =
  | typeof NONE
  | typeof LIGHT
  | typeof HEAVY
  | typeof EXTREME;

export const STANDARD = "Standard";
export const POOR_FIELD_CONDITIONS = "Poor Field Conditions";
export const POOR_ROAD_CONDITIONS = "Poor Road Conditions";
export const SWAMP = "Swamp";
export const ISLAND = "Island";
export type SiteAccessibility =
  | typeof STANDARD
  | typeof POOR_FIELD_CONDITIONS
  | typeof POOR_ROAD_CONDITIONS
  | typeof SWAMP
  | typeof ISLAND;

export default interface DamageAssessment {
  jobId: string;
  circuit: string;
  okOnArrival: boolean;
  notes: string;
  numberOfBrokenPoles: number;
  arePolesOrdered?: boolean | null;
  brokenCrossArms: number;
  spansWireDown: number;
  isOilSpill?: boolean | null;
  voltage: Voltage[];
  damagedEquipmentCount?: number;
  damageLocation: DamageLocation | null;
  truckAccessible: boolean | null;
  treeTrim: TreeTrim | null;
  siteAccessibility: SiteAccessibility | null;
  siteAccessibilityNotes: string;
  brokenPolesLocations: DamageAssessmentLocation[];
  crossArmsLocations: DamageAssessmentLocation[];
  photos: string[];
  createUser?: string;
  createDate?: string;
  submittedOn?: string;
  submittedBy?: string;
}

export interface DamageAssessmentOkOnArrival {
  jobId: string;
  circuit: string;
  okOnArrival: true;
  notes: string;
  createUser?: string;
  createDate?: string;
  submittedOn?: string;
  submittedBy?: string;
}

export interface DamageAssessmentLocation {
  location: Location;
  notes: string;
}

export interface DamageAssessmentLocationComment {
  location: Location;
  notes: string;
  link: string;
}

export interface Location {
  lat: number;
  long: number;
}

export interface DamageAssessmentComment extends DamageAssessment {
  name: string;
  userId: string;
  brokenPolesLocations: DamageAssessmentLocationComment[];
  crossArmsLocations: DamageAssessmentLocationComment[];
}

export interface DamageAssessmentOnOnArrivalComment
  extends DamageAssessmentOkOnArrival {
  name: string;
  userId: string;
}

const headers = {
  headers: {
    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_SUB_KEY,
  },
};

const config: AxiosRequestConfig = {
  baseURL: `${process.env.REACT_APP_API_URL}/taskmanagement`,
  ...headers,
};

export const getDAForm = async (jobId: string) => {
  const params = {
    jobId,
  };
  const res = http.get<DamageAssessment>("/getDamageAssessment", {
    ...config,
    params,
  });
  return res;
};

export const uploadImage = async (image: File) => {
  const data = new FormData();
  data.append("file", image);
  const res = await http.post<{ message: string }>(
    "/uploadImage",
    data,
    config
  );
  return res;
};

export const saveDAForm = async (DAForm: DamageAssessment) => {
  const res = http.post("/saveDamageAssessment", DAForm, config);
  return res;
};

export const submitDAForm = async (
  DAForm: DamageAssessment | DamageAssessmentOkOnArrival
) => {
  const res = http.post("/submitDamageAssessment", DAForm, config);
  return res;
};
