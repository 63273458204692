import React from "react";
import clsx from "clsx";

export const RadioControl: React.FC<{
  label: string;
  value: string | null;
  options: string[];
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  setValue: (value: string) => void;
  name: string;
}> = ({ label, value, setValue, required, disabled, options, name, error }) => {
  return (
    <div className={clsx("flex flex-col", disabled && "disabled")}>
      <div className="flex flex-no-wrap mb-0.5">
        <label
          className={clsx("font-medium mr-0.25", error && "error-control")}
        >
          {label}
        </label>
        {required && <p className="text-red-600">*</p>}
      </div>
      <div className="flex flex-wrap">
        {options.map((x) => (
          <div key={x} className="flex flex-no-wrap mr-1.25 items-center">
            <input
              checked={x === value}
              disabled={disabled}
              type="radio"
              id={x}
              value={x}
              name={name}
              onChange={(e) => setValue(e.target.value)}
            />
            <label className="ml-0.5" htmlFor={x}>
              {x}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};
