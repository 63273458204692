import { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import { WorkOrderModel } from "../../lib/models/WorkOrder";
import WorkOrderLogItem from "./WorkOrderLogItem";

const WorkOrderLogModal: React.FC<{ data: WorkOrderModel }> = ({ data }) => {
  const [disableViewWorkLog, setDisableViewWorkLog] = useState<boolean>(false);

  useEffect(() => {
    setDisableViewWorkLog(!data.worklog || data.worklog.length === 0);
  }, [data]);

  return (
    <>
      <Popup
        closeOnDocumentClick={true}
        modal={true}
        overlayStyle={{ cursor: "default" }}
        contentStyle={{ cursor: "default" }}
        className="responsive-modal"
        trigger={
          <button
            disabled={disableViewWorkLog}
            className="mr-0.5 mb-0.25 border border-dte-500 py-0.25 px-1 text-dte-500 disabled:bg-gray-300 disabled:text-gray-500 disabled:border-gray-200"
          >
            View Full Work Log
          </button>
        }
      >
        {(close) => (
          <div className="justify-center text-black mx-2 my-1 overflow-auto">
            <p className="mb-2 text-center text-xl">
              Work Log for {data.wonum}
            </p>
            <div className="h-32 overflow-auto">
              {data.worklog.map((log) => (
                <div className="mb-1">
                  <WorkOrderLogItem data={log} />
                </div>
              ))}
            </div>

            <div className="w-full text-right">
              <button
                className="bg-dte-500 py-0.25 px-1 text-white"
                onClick={() => close()}
              >
                Close Work Log
              </button>
            </div>
          </div>
        )}
      </Popup>
    </>
  );
};

export default WorkOrderLogModal;
