import React, { useEffect } from "react";
import Popup from "reactjs-popup";
import * as util from "../../lib/Utility";
import * as api from "../../lib/Api";
import { useAppSelector, useAppDispatch } from "../../lib/hooks";
import { setLoading } from "../../features/jobSlice";
import TreeTrimConfirmQuestions from "../TreeTrim/TreeTrimConfirmQuestions";
import { validateTreeTrimConfirmQuestions } from "../../lib/models/TreeTrimConfirmQuestionsModel";
import {
  getTreeTrimActivitiesForJob,
  getTreeTrimOutageInfoForJob,
  saveTreeTrimActivities,
  saveTreeTrimOutageInfo,
} from "../../lib/api/treeTrimApi";
import { trackLocationData } from "../../lib/api/trackLocationApi";

type Action = "Cancel" | "Close";

const TreeTrimCancelCloseModal = (props: {
  job: api.JobDetail;
  afterSubmit: Function;
  action: Action;
}) => {
  const dispatch = useAppDispatch();
  const role = useAppSelector((state) => state.role.value);
  const activities = useAppSelector((state) => state.treeTrim.activities);
  const outageInfo = useAppSelector((state) => state.treeTrim.outageInfo);
  const treeTrimQuestions = useAppSelector((state) => state.treeTrim.questions);
  const [errors, setCancelCloseErrors] = React.useState<string[]>([]);
  const [cancelReason, setCancelReason] = React.useState("");
  const [submitClicked, setSubmitClicked] = React.useState<boolean>(false);

  const defaultQuestionErrorMessages = {
    activityErrorMessage:
      "A tree trim activity form must be submitted before this job can be closed.",
    outageErrorMessage:
      "An outage information form must be submitted before the job sub type can be changed.",
  };

  const submit = async (closeModal: () => void) => {
    setSubmitClicked(true);
    if (validate()) {
      closeModal();
      await cancelOrClose();
      setSubmitClicked(false);
    }
  };

  const validate = () => {
    let isValidated = false;
    setCancelCloseErrors([]);
    if (props.action === "Close") {
      const questionErrors = validateTreeTrimConfirmQuestions(
        treeTrimQuestions,
        activities.length > 0,
        outageInfo.length > 0
      );
      setCancelCloseErrors(questionErrors);
      isValidated = questionErrors.length === 0;
    } else {
      console.log("cancelReason", cancelReason);
      isValidated = cancelReason !== "";
      if (!isValidated) {
        setCancelCloseErrors(["cancelReason"]);
      }
    }
    console.log(errors);
    return isValidated;
  };

  const updateTreeTrimOnClose = async (jobId: string) => {
    const treeTrimActivityResult = await getTreeTrimActivitiesForJob(jobId);
    if (treeTrimActivityResult.tag === "ok") {
      treeTrimActivityResult.data.forEach((tt) => {
        tt.jobClosureDate = new Date().toISOString();
      });
      await saveTreeTrimActivities(treeTrimActivityResult.data, []);
    }

    const treeTrimOutageResult = await getTreeTrimOutageInfoForJob(jobId);
    if (treeTrimOutageResult.tag === "ok") {
      treeTrimOutageResult.data.forEach((tt) => {
        tt.jobClosureDate = new Date().toISOString();
      });
      await saveTreeTrimOutageInfo(treeTrimOutageResult.data, []);
    }
  };

  const cancelOrClose = async () => {
    dispatch(setLoading(true));
    const crewsRes = await api.getCrewAssignmentByJobId([props.job.jobId]);
    if (crewsRes.tag === "ok") {
      const jobList = Array.isArray(crewsRes.data.job.jobList)
        ? crewsRes.data.job.jobList[0]
        : crewsRes.data.job.jobList;

      let crewsIds: string[] = [];
      if (Array.isArray(jobList.crewAssignments)) {
        crewsIds = [...jobList.crewAssignments.map((x) => x.crewId)];
      } else {
        crewsIds = [jobList.crewAssignments.crewId];
      }
      const pReq: api.CrewStatusUpdateReq = {
        msgId: props.job.jobId,
        jobId: props.job.jobId,
        action: "PR",
      };

      await trackLocationData("updateCrewStatus", crewsIds.join(","), role!);

      const preempts = await Promise.allSettled(
        crewsIds
          .filter((x) => x !== "")
          .map((x) => api.updateCrewStatus(x, pReq))
      );
      if (
        preempts.every((x) => x.status === "fulfilled" && x.value.tag === "ok")
      ) {
        await trackLocationData("updateJob", props.job.jobId, role!);

        const res = await api.updateJob(props.job.jobId, {
          msgId: props.job.jobId,
          jobStatus: {
            jobStatus: props.action,
            comments: `${role?.name} ${
              props.action === "Cancel" ? "cancelled" : "closed"
            } job ${props.job.jobId} ${
              props.action === "Cancel" && `with Disposition: ${cancelReason}`
            }`,
          },
          jobComments: `${role?.name} ${
            props.action === "Cancel" ? "cancelled" : "closed"
          } job ${props.job.jobId} ${
            props.action === "Cancel" && `with Disposition: ${cancelReason}`
          }`,
        });
        if (res.tag === "ok") {
          if (props.action === "Close") {
            updateTreeTrimOnClose(props.job.jobId);
          }
          alert(`Job ${props.action} Successful`);
          props.afterSubmit();
        } else {
          alert(
            "Job update failed, check your internet connection. You can resend the request through the Drafts page"
          );
          props.afterSubmit();
        }
      } else {
        alert(
          "Job update failed, check your internet connection. You can resend the request through the Drafts page"
        );
        props.afterSubmit();
      }
    } else {
      alert(
        "Job update failed, check your internet connection. You can resend the request through the Drafts page"
      );
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    console.log(submitClicked);
    if (submitClicked) {
      validate();
    }
  }, [treeTrimQuestions, cancelReason]);

  return (
    <>
      <Popup
        closeOnDocumentClick={true}
        modal={true}
        disabled={!util.cancelCloseStatusCodes.includes(props.job.jobStatus)}
        overlayStyle={{ cursor: "default" }}
        contentStyle={{ cursor: "default" }}
        onClose={() => {
          setCancelReason("");
        }}
        onOpen={() => {
          setCancelCloseErrors([]);
          if (props.action === "Cancel") {
            setCancelReason("");
          }
        }}
        className="responsive-modal"
        trigger={
          <button
            className="mr-0.5 mb-0.25 bg-dte-500 py-0.25 px-0.5 text-white"
            disabled={
              !util.cancelCloseStatusCodes.includes(props.job.jobStatus)
            }
          >
            {props.action} Job
          </button>
        }
      >
        {(closeModal) => (
          <div className="text-black py-1 flex justify-center flex-col">
            {props.action === "Close" ? (
              <p className="mb-1 text-center text-2xl">Close Job</p>
            ) : (
              <p className="mb-1 text-center">Choose a reason</p>
            )}
            {props.action === "Cancel" && (
              <>
                {errors.length > 0 ? (
                  <span className="text-red-500">
                    You must select a cancel reason
                  </span>
                ) : (
                  <></>
                )}
                <button
                  className={`${
                    cancelReason === "OK"
                      ? "bg-white border border-dte-500 text-dte-500"
                      : "border border-dte-500 bg-dte-500 text-white"
                  } mx-0.5 my-0.5 py-0.5 px-0.75`}
                  onClick={() => setCancelReason("OK")}
                >
                  OK on Arrival
                </button>
                <button
                  className={`${
                    cancelReason === "FOR"
                      ? "bg-white border border-dte-500 text-dte-500"
                      : "border border-dte-500 bg-dte-500 text-white"
                  } mx-0.5 my-0.5 py-0.5 px-0.75`}
                  onClick={() => setCancelReason("FOR")}
                >
                  Foreign WIRE/PLD
                </button>
                <button
                  className={`${
                    cancelReason === "CJOB"
                      ? "bg-white border border-dte-500 text-dte-500"
                      : "border border-dte-500 bg-dte-500 text-white"
                  } mx-0.5 my-0.5 py-0.5 px-0.75`}
                  onClick={() => setCancelReason("CJOB")}
                >
                  C-JOB
                </button>
                <button
                  className={`${
                    cancelReason === "MAINT"
                      ? "bg-white border border-dte-500 text-dte-500"
                      : "border border-dte-500 bg-dte-500 text-white"
                  } mx-0.5 my-0.5 py-0.5 px-0.75`}
                  onClick={() => setCancelReason("MAINT")}
                >
                  OK till Maintenance
                </button>
                <button
                  className={`${
                    cancelReason === "SERVCS"
                      ? "bg-white border border-dte-500 text-dte-500"
                      : "border border-dte-500 bg-dte-500 text-white"
                  } mx-0.5 my-0.5 py-0.5 px-0.75`}
                  onClick={() => setCancelReason("SERVCS")}
                >
                  Advised Service/Brush Policy
                </button>
              </>
            )}
            {props.action === "Close" && (
              <TreeTrimConfirmQuestions
                errorMessages={defaultQuestionErrorMessages}
                errors={errors}
              />
            )}
            <div className="flex flex-col items-center">
              <div className="w-1/2 inline-flex flex-no-wrap justify-between items-center mt-2 mb-0.5">
                <button
                  className="px-1 py-0.5 bg-red-600 text-white border border-solid border-red-600"
                  onClick={() => {
                    closeModal();
                  }}
                >
                  Cancel
                </button>
                <button
                  className="px-1 py-0.5 bg-dte-500 text-white border border-solid border-dte-500
                                    disabled:bg-gray-300 disabled:text-gray-500 disabled:border-gray-200"
                  disabled={errors.length > 0}
                  onClick={() => {
                    submit(closeModal);
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        )}
      </Popup>
    </>
  );
};

export default TreeTrimCancelCloseModal;
