import { Link } from "react-router-dom";
import { CrewAssignmentNotification } from "../../features/crewAssignmentNotificationsSlice";

const NotificationMessage = (props: {
  notification: CrewAssignmentNotification;
}) => {
  return (
    <>
      {props.notification.crewAssignment === "Assigned" ? (
        <span>
          Crew {props.notification.crewId} has been assigned a new job&nbsp;
        </span>
      ) : (
        <span>
          Crew {props.notification.crewId} has been dispatched to job&nbsp;
        </span>
      )}
      <Link
        className="text-dte-500 underline"
        to={`/details/${props.notification.jobId}`}
      >
        {props.notification.jobId}
      </Link>
      {props.notification.jobType !== null ? (
        <span className="mx-0.5">Type: {props.notification.jobType}</span>
      ) : (
        <></>
      )}
      {props.notification.jobSubType !== null ? (
        <span className="mx-0.5">SubType: {props.notification.jobSubType}</span>
      ) : (
        <></>
      )}
    </>
  );
};

export default NotificationMessage;
