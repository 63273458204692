import React from "react";
import { loadModules } from "esri-loader";
import { getToken } from "../auth/authProvider";
import * as U from "../lib/Utility";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationArrow, faStop } from "@fortawesome/free-solid-svg-icons";
import { useAppSelector } from "../lib/hooks";

import type EsriMap from "esri/map";
import { useLocation } from "react-router-dom";

const MINUTE_IN_MILLISECONDS = 60000;

type EMap = typeof import("esri/map");

type IOArgs = {
  url: string;
  content: IOArgsContent;
  callbackParamName: string;
  timeout: number;
  handleAs: string;
  jsonp: string;
  query: IOArgsContent;
  headers?: object;
};

type IOArgsContent = {
  f: string;
};

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const requestPreCallback = (ioArgs: IOArgs, token: string) => {
  if (ioArgs.url.startsWith(process.env.REACT_APP_API_URL ?? "")) {
    ioArgs.headers = {
      authorization: "Bearer " + token,
      "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_SUB_KEY,
    };
  }
  return ioArgs;
};

const MapPage = () => {
  const role = useAppSelector((state) => state.role.value);
  const query = useQuery();
  const mapDivRef = React.useRef<HTMLDivElement>(null);
  const map = React.useRef<EsriMap | null>(null);
  const dteVehicleLayer = React.useRef<
    import("esri/layers/ArcGISDynamicMapServiceLayer") | null
  >(null);
  const treeTrimLayer = React.useRef<
    import("esri/layers/ArcGISDynamicMapServiceLayer") | null
  >(null);
  const electricLayer = React.useRef<
    import("esri/layers/ArcGISDynamicMapServiceLayer") | null
  >(null);
  const contractCrewLayer = React.useRef<
    import("esri/layers/FeatureLayer") | null
  >(null);
  const outageLayer = React.useRef<import("esri/layers/FeatureLayer") | null>(
    null
  );

  const [showLegend, setShowLegend] = React.useState(false);

  const [tracking, toggleTracking] = React.useState(false);

  const trackingID = React.useRef<number | null>(null);

  const [showDTEVehicles, setShowDTEVehicles] = React.useState(true);
  const [showContractCrewTrucks, setShowContractCrewTrucks] =
    React.useState(true);
  const [showTreeTrimTrucks, setShowTreeTrimTrucks] = React.useState(true);

  const [showToggles, setShowToggles] = React.useState(false);

  const basemapToggleRef = React.useRef<HTMLDivElement>(null);
  const searchRef = React.useRef<HTMLDivElement>(null);

  const authTokenRef = React.useRef<string>("");
  const intervalRef = React.useRef<any>(null);

  React.useEffect(() => {
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  React.useEffect(() => {
    if (map.current) {
      if (dteVehicleLayer.current) {
        dteVehicleLayer.current.setVisibility(showDTEVehicles);
      }
      if (contractCrewLayer.current) {
        contractCrewLayer.current.setVisibility(showContractCrewTrucks);
      }
      if (treeTrimLayer.current) {
        treeTrimLayer.current.setVisibility(showTreeTrimTrucks);
      }
    }
  }, [showDTEVehicles, showContractCrewTrucks, showTreeTrimTrucks]);

  React.useEffect(() => {
    const loadMap = async () => {
      const [
        ArcGISMap,
        EsriConfig,
        EsriRequest,
        DynamicLayer,
        FeatureLayer,
        InfoTemplate,
        BasemapToggle,
        Search,
        Point,
        Popup,
      ] = await loadModules<
        [
          EMap,
          typeof import("esri/config"),
          typeof import("esri/request"),
          typeof import("esri/layers/ArcGISDynamicMapServiceLayer"),
          typeof import("esri/layers/FeatureLayer"),
          typeof import("esri/InfoTemplate"),
          typeof import("esri/dijit/BasemapToggle"),
          typeof import("esri/dijit/Search"),
          typeof import("esri/geometry/Point"),
          typeof import("esri/dijit/Popup")
        ]
      >(
        [
          "esri/map",
          "esri/config",
          "esri/request",
          "esri/layers/ArcGISDynamicMapServiceLayer",
          "esri/layers/FeatureLayer",
          "esri/InfoTemplate",
          "esri/dijit/BasemapToggle",
          "esri/dijit/Search",
          "esri/geometry/Point",
          "esri/dijit/Popup",
        ],
        {
          css: true,
          url: "https://js.arcgis.com/3.33/",
        }
      );

      const vehicleInfoTemplate = new InfoTemplate();
      vehicleInfoTemplate.setContent(function (graphic: any) {
        var templateString =
          "Vehicle ID: " +
          graphic.attributes.VEHICLEID +
          "<br/>Usage: " +
          graphic.attributes.USAGE +
          "<br/>Description: " +
          graphic.attributes.DESCRIPTION +
          "<br/>Svc Center: " +
          graphic.attributes.LOCATION +
          "<br/>Crew: " +
          (graphic.attributes.CREWID ? graphic.attributes.CREWID : "") +
          "<br/>Stationary Time: " +
          graphic.attributes.IDLETIME +
          "<br/><br/><br/>";

        if (role?.supervisor) {
          //templateString = addBreadcrumbLink(graphic, templateString);
        }

        return (
          templateString +
          buildDrivingDirectionsOnClickHandlerLink(
            graphic.attributes.LATITUDE,
            graphic.attributes.LONGITUDE
          )
        );
      });
      vehicleInfoTemplate.setTitle("${VEHICLEID}");

      const vehicleTemplate = {
        0: { infoTemplate: vehicleInfoTemplate },
        1: { infoTemplate: vehicleInfoTemplate },
        2: { infoTemplate: vehicleInfoTemplate },
        3: { infoTemplate: vehicleInfoTemplate },
        4: { infoTemplate: vehicleInfoTemplate },
        5: { infoTemplate: vehicleInfoTemplate },
        6: { infoTemplate: vehicleInfoTemplate },
        7: { infoTemplate: vehicleInfoTemplate },
        8: { infoTemplate: vehicleInfoTemplate },
      };

      const contractTruckInfoTemplate = new InfoTemplate();
      contractTruckInfoTemplate.setTitle("${COMPANYID} - ${VEHICLEID}");
      contractTruckInfoTemplate.setContent(function (graphic: any) {
        return (
          "Company: " +
          graphic.attributes.CONTRACTCOMPANY +
          "<br/>Vehicle ID: " +
          graphic.attributes.VEHICLEID +
          "<br/>Description: " +
          graphic.attributes.VEHICLETYPE +
          buildDrivingDirectionsOnClickHandlerLink(
            graphic.attributes.LATITUDE,
            graphic.attributes.LONGITUDE
          )
        );
      });

      const treeTrimTemplate = {
        0: { infoTemplate: contractTruckInfoTemplate },
        1: { infoTemplate: contractTruckInfoTemplate },
        2: { infoTemplate: contractTruckInfoTemplate },
        3: { infoTemplate: contractTruckInfoTemplate },
        4: { infoTemplate: contractTruckInfoTemplate },
      };

      const outageInfoTemplate = new InfoTemplate();
      outageInfoTemplate.setTitle("${JOB_ID}");
      outageInfoTemplate.setContent(function (graphic: any) {
        const jobId = graphic.attributes.JOB_ID;
        let templateString = outageTemplateString(jobId);
        return templateString;
      });

      const addDynamicLayers = (token: string) => {
        dteVehicleLayer.current = new DynamicLayer(
          U.buildLayerUrl(
            U.dteVehicleLayerPath,
            token,
            process.env.REACT_APP_OCP_SUB_KEY ?? ""
          ),
          {
            id: U.dteVehicleLayerId,
            infoTemplates: vehicleTemplate,
          }
        );

        if (!role?.supervisor) {
          //Remove the Service Ops Supervisor Pickups from the map
          var removeSOSupervisors = [];
          removeSOSupervisors[3] = "USAGE not like 'SERVICE OPS%'";
          removeSOSupervisors[4] = "USAGE not like 'SERVICE OPS%'";
          removeSOSupervisors[5] = "USAGE not like 'SERVICE OPS%'";
          dteVehicleLayer.current.setLayerDefinitions(removeSOSupervisors);
        }

        treeTrimLayer.current = new DynamicLayer(
          U.buildLayerUrl(
            U.treeTrimTruckLayerPath,
            token,
            process.env.REACT_APP_OCP_SUB_KEY ?? ""
          ),
          {
            id: U.treeTrimTruckLayerId,
            infoTemplates: treeTrimTemplate,
          }
        );

        electricLayer.current = new DynamicLayer(
          U.buildLayerUrl(
            U.electricFacilityLayerPath,
            token,
            process.env.REACT_APP_OCP_SUB_KEY ?? ""
          ),
          {
            id: U.electricFacilitiesLayerId,
            infoTemplates: {},
          }
        );

        map.current?.addLayers([
          dteVehicleLayer.current,
          treeTrimLayer.current,
          electricLayer.current,
        ]);
        map.current?.reorderLayer(dteVehicleLayer.current, 100);
        map.current?.reorderLayer(treeTrimLayer.current, 98);
        map.current?.reorderLayer(electricLayer.current, 97);
      };

      //@ts-ignore
      EsriConfig.defaults.io.corsEnabledServers.push(
        process.env.REACT_APP_API_URL
      );

      /*DO NOT CHANGE: When doing requests to ESRI servers, if the query params + url 
      is longer that this character limit (default: 2000) then the SDK will switch to using a POST
      instead of a GET. That POST request will be returned json which includes a url that the SDK
      will then use to fetch the layer image. HOWEVER, the ESRI service is not aware its being proxied
      so the url returned will be an internal DTE ESRI url that will not work from the external network.
      So the POST request will never work. So the solution is to increase the postLength to make sure POSTs
      will not be used. This property is undocumented in ArcGIS 3.x, but is documented under a different name
      in ArcGIS 4.x at https://developers.arcgis.com/javascript/latest/api-reference/esri-config.html#request
      */
      EsriConfig.defaults.io.postLength = 10000;

      authTokenRef.current = await getToken();

      EsriRequest.setRequestPreCallback((ioArgs: IOArgs) =>
        requestPreCallback(ioArgs, authTokenRef.current)
      );

      intervalRef.current = setInterval(() => {
        getToken().then((token) => {
          EsriRequest.setRequestPreCallback((ioArgs: IOArgs) =>
            requestPreCallback(ioArgs, token)
          );
          if (token !== authTokenRef.current && map.current) {
            dteVehicleLayer.current &&
              map.current.removeLayer(dteVehicleLayer.current);
            treeTrimLayer.current &&
              map.current.removeLayer(treeTrimLayer.current);
            electricLayer.current &&
              map.current.removeLayer(electricLayer.current);
            addDynamicLayers(token);
          }
          authTokenRef.current = token;
        });
      }, MINUTE_IN_MILLISECONDS);

      const long = Number(query.get("x"));
      const lat = Number(query.get("y"));

      const startCenter = long && lat ? new Point(long, lat) : null;

      const popup = new Popup(
        { visibleWhenEmpty: false, pagingControls: true, pagingInfo: true },
        "popup"
      );

      map.current = new ArcGISMap(mapDivRef.current!, {
        center: startCenter ?? [-83.5, 42.5],
        zoom: long && lat ? 17 : 9,
        basemap: "topo-vector",
        infoWindow: popup,
      });

      addDynamicLayers(authTokenRef.current);

      outageLayer.current = new FeatureLayer(U.outageJobLayerURL, {
        id: "outage-layer",
        outFields: ["*"],
        opacity: 0.5,
        infoTemplate: outageInfoTemplate,
      });

      contractCrewLayer.current = new FeatureLayer(
        U.contractCrewTruckLayerPath,
        {
          id: U.contractCrewTruckLayerId,
          outFields: ["*"],
          infoTemplate: contractTruckInfoTemplate,
          definitionExpression: contractCrewLayerDefinitionExpression,
        }
      );

      map.current?.addLayers([contractCrewLayer.current, outageLayer.current]);
      map.current?.reorderLayer(contractCrewLayer.current, 99);
      map.current?.reorderLayer(outageLayer.current, 96);

      const toggle = new BasemapToggle(
        {
          map: map.current!,
          basemap: "satellite",
        },
        basemapToggleRef.current!
      );
      toggle.startup();

      const searchLayer = new FeatureLayer(U.searchLayerPath, {
        infoTemplate: vehicleInfoTemplate,
        outFields: ["*"],
        definitionExpression:
          "USAGE like 'SERVICE OPS%' AND DESCRIPTION <> 'PICKUP'",
        id: "SearchLayer",
      });

      const search = new Search(
        {
          map: map.current!,
          enableSourcesMenu: true,
          enableSearchingAll: false,
        },
        searchRef.current!
      );
      const sources = search.get("sources");
      sources.push({
        featureLayer: searchLayer,
        placeholder: "1234",
        name: "CREW ID",
        enableLabel: false,
        searchFields: ["CREWID"],
        displayField: "CREWID",
        exactMatch: false,
        outFields: ["*"],
        infoTemplate: vehicleInfoTemplate,
      });
      sources.push({
        featureLayer: searchLayer,
        placeholder: "888111",
        name: "VEHICLE ID",
        enableLabel: false,
        searchFields: ["VEHICLEID"],
        displayField: "VEHICLEID",
        exactMatch: false,
        outFields: ["*"],
      });
      sources.push({
        featureLayer: outageLayer.current,
        searchFields: ["JOB_ID"],
        suggestionTemplate: "${JOB_ID}",
        exactMatch: false,
        outFields: ["JOB_ID"],
        name: "JOB ID",
        placeholder: "D12345678912",
        maxResults: 6,
        maxSuggestions: 6,
        enableSuggestions: true,
        minCharacters: 0,
        opacity: 0.5,
        searchQueryParams: {
          distance: 5000,
        },
      });

      search.set("sources", sources);

      search.startup();

      return () => {
        if (map.current) {
          // destroy the map view
          map.current = null!;
        }
      };
    };

    loadMap();
  }, []);

  React.useEffect(() => {
    const goToLocation = async () => {
      const [Point, SimpleMarkerSymbol, Graphic, SimpleLineSymbol, Color] =
        await loadModules<
          [
            typeof import("esri/geometry/Point"),
            typeof import("esri/symbols/SimpleMarkerSymbol"),
            typeof import("esri/graphic"),
            typeof import("esri/symbols/SimpleLineSymbol"),
            typeof import("esri/Color")
          ]
        >([
          "esri/geometry/Point",
          "esri/symbols/SimpleMarkerSymbol",
          "esri/graphic",
          "esri/symbols/SimpleLineSymbol",
          "esri/Color",
        ]);
      if (navigator.geolocation && map.current) {
        trackingID.current = navigator.geolocation.watchPosition(
          (res) => {
            const point = new Point(res.coords.longitude, res.coords.latitude);
            const maxZoom = map.current?.getMaxZoom() ?? 17;
            const outline = new SimpleLineSymbol(
              "solid",
              new Color("white"),
              2
            );
            const circleSymbol = new SimpleMarkerSymbol(
              SimpleMarkerSymbol.STYLE_CIRCLE,
              14,
              outline,
              new Color("#007AC2")
            );

            const graphic = new Graphic(point, circleSymbol);
            map.current?.graphics.clear();
            map.current?.graphics.add(graphic);
            map.current?.centerAndZoom(point, maxZoom - 4);
          },
          (err) => {
            console.log(err);
            if (err.code === 1) {
              alert(
                "Error getting your location, make sure your settings allow this site to use location data"
              );
            } else {
              alert("Error getting your location");
            }
          },
          {
            enableHighAccuracy: true,
            timeout: 10000,
            maximumAge: 0,
          }
        );
      }
    };
    if (tracking) {
      goToLocation();
    } else {
      if (navigator.geolocation && map.current) {
        navigator.geolocation.clearWatch(trackingID.current!);
        map.current.graphics.clear();
        trackingID.current = null;
      }
    }
  }, [tracking]);

  return (
    <div className="h-full max-w-full overflow-x-hidden relative">
      <div className="h-full w-full overflow-hidden" ref={mapDivRef} />
      <div id="popup"></div>
      <div ref={searchRef} />
      <button
        onClick={() => toggleTracking(!tracking)}
        style={{ border: "1px solid #57585A" }}
        className="absolute z-10 rounded top-0 w-2 h-1.75 left-0 mt-5.5 ml-1.25 bg-white p-0.25 text-black hover:bg-gray-300 flex justify-center items-center"
      >
        {tracking ? (
          <FontAwesomeIcon icon={faStop} size="xs" className="text-red-600" />
        ) : (
          <FontAwesomeIcon
            icon={faLocationArrow}
            size="xs"
            className="text-dte-500"
          />
        )}
      </button>
      <div ref={basemapToggleRef} />
      <button
        className="absolute z-10 bottom-0 left-0 mb-3.5 mx-1 border border-black bg-white p-0.25 text-black hover:bg-gray-300"
        onClick={() => setShowLegend(!showLegend)}
      >
        {showLegend ? "Hide" : "Show"} Legend
      </button>
      {showLegend ? (
        <div className="absolute bg-white top-0 right-0 text-black mt-4 sm:mt-1 mx-1 overflow-y-auto overflow-x-hidden w-10 px-1 py-0.25">
          <div>
            <div>
              <h1>Outage Colors</h1>
              <p className="text-xs">Customers Affected</p>
              <div className="flex flex-col">
                <div className="inline-flex flex-no-wrap items-center my-0.5">
                  <span className="w-1.5 h-1.5 bg-outages-1" />
                  <label className="ml-0.5">{"< 99"}</label>
                </div>
                <div className="inline-flex flex-no-wrap items-center my-0.5">
                  <span className="w-1.5 h-1.5 bg-outages-2" />
                  <label className="ml-0.5">{"100 - 500"}</label>
                </div>
                <div className="inline-flex flex-no-wrap items-center my-0.5">
                  <span className="w-1.5 h-1.5 bg-outages-3" />
                  <label className="ml-0.5">{"501 - 1500"}</label>
                </div>
                <div className="inline-flex flex-no-wrap items-center my-0.5">
                  <span className="w-1.5 h-1.5 bg-outages-4" />
                  <label className="ml-0.5">{"1501 - 2500"}</label>
                </div>
                <div className="inline-flex flex-no-wrap items-center my-0.5">
                  <span className="w-1.5 h-1.5 bg-outages-5" />
                  <label className="ml-0.5">{"> 2501"}</label>
                </div>
              </div>
            </div>
            <div>
              <h1>Truck Colors</h1>
              <p className="text-xs">Hours Stationary</p>
              <div className="flex flex-col">
                <div className="inline-flex flex-no-wrap items-center my-0.5">
                  <span className="w-1.5 h-1.5 bg-idle-1" />
                  <label className="ml-0.5">{"< 2"}</label>
                </div>
                <div className="inline-flex flex-no-wrap items-center my-0.5">
                  <span className="w-1.5 h-1.5 bg-idle-2" />
                  <label className="ml-0.5">{"2 - 4"}</label>
                </div>
                <div className="inline-flex flex-no-wrap items-center my-0.5">
                  <span className="w-1.5 h-1.5 bg-idle-3" />
                  <label className="ml-0.5">{"> 4"}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      <button
        className="absolute z-10 bottom-0 left-0 w-10 mb-1 ml-1 border border-black bg-white p-0.25 text-black hover:bg-gray-300"
        onClick={() => setShowToggles(!showToggles)}
      >
        {showToggles ? "Hide" : "Show"} Layer Toggles
      </button>
      {showToggles ? (
        <div className="absolute z-10 bg-white text-black mb-3.5 mx-1 left-0 bottom-0 border border-black flex flex-col px-0.25 py-0.25">
          <button
            onClick={() => setShowDTEVehicles(!showDTEVehicles)}
            className={`border border-black p-0.25 my-0.25 w-13 hover:bg-gray-300 ${
              !showDTEVehicles ? "opacity-50" : ""
            }`}
          >
            {showDTEVehicles ? "Hide" : "Show"} DTE Vehicles
          </button>
          <button
            onClick={() => setShowContractCrewTrucks(!showContractCrewTrucks)}
            className={`border border-black p-0.25 my-0.25 w-13 hover:bg-gray-300 ${
              !showContractCrewTrucks ? "opacity-50" : ""
            }`}
          >
            {showContractCrewTrucks ? "Hide" : "Show"} Contract Crew Trucks
          </button>
          <button
            onClick={() => setShowTreeTrimTrucks(!showTreeTrimTrucks)}
            className={`border border-black p-0.25 my-0.25 w-13 hover:bg-gray-300 ${
              !showTreeTrimTrucks ? "opacity-50" : ""
            }`}
          >
            {showTreeTrimTrucks ? "Hide" : "Show"} Tree Trim Trucks
          </button>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default MapPage;

//const twentyFourHoursInMinutes = 1400;
const contractCrewLayerDefinitionExpression =
  "COMPANYID='ASP' or COMPANYID='EXT' or COMPANYID='HYD' or COMPANYID='LEC'";

// const addBreadcrumbLink = (graphic: any, templateString: any) => {
//   if (graphic.attributes.IDLETIME >= twentyFourHoursInMinutes) {
//     templateString += "No breadcrumbs for this vehicle";
//   } else {
//     templateString +=
//       '<a href="#breadcrumb/' +
//       graphic.attributes.VEHICLEID +
//       '">Breadcrumbs</a>';
//   }
//   return templateString;
// };

function buildDrivingDirectionsLink(onClickFunctionString: string) {
  return (
    '<br/><a onClick="' + onClickFunctionString + '">Driving Directions</a>'
  );
}

function buildGoogleMapsOnClickFunction(latLongString: string) {
  return (
    "window.open('http://maps.google.com/maps?ll=" +
    latLongString +
    "&q=loc:" +
    latLongString +
    "&t=m', '_system')"
  );
}

function buildAppleMapsOnClickFunction(latLongString: string) {
  return (
    "window.open('http://maps.apple.com/maps?daddr=" +
    latLongString +
    "', '_system')"
  );
}

function buildLatitudeLongitudeString(
  latitude: number | string,
  longitude: number | string
) {
  return latitude + "," + longitude;
}

function buildDrivingDirectionsOnClickHandlerLink(
  latitude: number | string,
  longitude: number | string
) {
  var latLongString = buildLatitudeLongitudeString(latitude, longitude);
  var onClickFunctionString;
  if (
    typeof navigator.platform !== "undefined" &&
    navigator.platform.toLowerCase() === "ios"
  ) {
    onClickFunctionString = buildAppleMapsOnClickFunction(latLongString);
  } else {
    onClickFunctionString = buildGoogleMapsOnClickFunction(latLongString);
  }
  return buildDrivingDirectionsLink(onClickFunctionString);
}

const outageTemplateString = (jobId: string | number) =>
  `${
    jobId
      ? `Job ID: ${jobId}<br/><a href='/details/${jobId}'>Go to Details</a><div id='drivingDirectionsLinkContainer'></div>`
      : "No information available"
  }`;
